import { gql } from "apollo-boost";
const config = require("../config/default.json");
export const baseUrl = config.app.baseUrl;

export const FETCH_USER = gql`
  mutation LogIn($loginid: String!, $password: String!) {
    login(loginid: $loginid, password: $password) {
      login_id
      email_address
      tokenid
      message
      usertype
      userid
      organization_id
      institution_id
      organization_name
      first_name
      middle_name
      last_name
      institution_name
      currency_code
      currency_symbol
      online_class
      online_payment
      language
      country_code
      locale
      full_day_hours
      half_day_hours
      organization_name
      path
      institutePhone
      instituteEmail
      address {
        line1
        line2
        city
        state
        zip_code
        country
      }
    }
  }
`;
export const LoginUser = gql`
  mutation startVerification($phoneNumber: String!) {
    startVerification(phoneNumber: $phoneNumber) {
      message
      id
      errorCode
    }
  }
`;
export const verifyOTPSend = gql`
  mutation verifyOTP($otp: String!, $id: Int!) {
    verifyOTP(otp: $otp, id: $id) {
      message
      id
      errorCode
    }
  }
`;
export const SignupForPrivateTeacher = gql`
  mutation signupForPrivateTeacher(
    $firstName: String!
    $lastName: String!
    $middleName: String!
    $email: String!
    $phoneNumber: String!
    $currency_code: String!
    $country_code: String!
    $experience_level: String!
    $total_experience: String!
    $background: String!
    $languages: [json!]
    $boards: [json!]
    $subjects: [json!]
    $teacherPricingAmount: String!
    $teacherPricingCurrencyCode: String!
    $availability: [availability!]
    $photo: immunization_doc
    $certificate: immunization_doc
    $education_qualifications: String!
  ) {
    signupForPrivateTeacher(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      currency_code: $currency_code
      country_code: $country_code
      experience_level: $experience_level
      total_experience: $total_experience
      background: $background
      languages: $languages
      boards: $boards
      subjects: $subjects
      teacherPricingAmount: $teacherPricingAmount
      teacherPricingCurrencyCode: $teacherPricingCurrencyCode
      availability: $availability
      photo: $photo
      certificate: $certificate
      education_qualifications: $education_qualifications
    ) {
      message
      id
      errorCode
    }
  }
`;
export const findPrivateTeachers = gql`
  query findPrivateTeachers(
    $subject: String
    $class: String
    $board: String
    $language: String
  ) {
    findPrivateTeachers(
      subject: $subject
      class: $class
      board: $board
      language: $language
    ) {
      id
      subjects {
        id
        name
      }
      boards {
        id
        name
      }

      languages {
        id
        name
        user_type
      }
      employees {
        id
        name {
          first_name
          middle_name
          last_name
        }
        total_experience
        phone
        email
      }
      teacher_availabilities {
        start_time
        end_time
        day
        institution_id
      }
      teacher_pricing {
        amount
        currency_code
      }
    }
  }
`;
export const addStudentForPrivateInstitute = gql`
  mutation addStudentForPrivateInstitute(
    $student_first_name: String!
    $student_middle_name: String!
    $student_last_name: String!
    $email: String!
    $phoneNumber: String!
    $father_first_name: String!
    $father_middle_name: String!
    $father_last_name: String!
    $institution_id: Int!
    $preference: String!
    $start_date: String!
    $Preferred_day_time: [Preferreddaytime]!
    $teacherEmailID: String!
    $teacherPhoneNumer: String!
    $teacher_id: Int!
    $language: String!
    $subject: String!
    $board: String!
    $start_time: String!
    $day: String!
  ) {
    addStudentForPrivateInstitute(
      student_first_name: $student_first_name
      student_middle_name: $student_middle_name
      student_last_name: $student_last_name
      email: $email
      phoneNumber: $phoneNumber
      father_first_name: $father_first_name
      father_middle_name: $father_middle_name
      father_last_name: $father_last_name
      institution_id: $institution_id
      preference: $preference
      start_date: $start_date
      Preferred_day_time: $Preferred_day_time
      teacherEmailID: $teacherEmailID
      teacherPhoneNumer: $teacherPhoneNumer
      teacher_id: $teacher_id
      language: $language
      subject: $subject
      board: $board
      start_time: $start_time
      day: $day
    ) {
      message
      id
      errorCode
    }
  }
`;
