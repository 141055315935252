import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SignUpDailog from "../SignUp/SignUpDailog";
import ConfirmNumber from "../SignUp/ConfirmNumber";
import FinishSignUp from "../SignUp/FinishSignUp";
import MessagePopUp from "../SignUp/MessagePopUp";
import JoinMeetingDailog from "./JoinMeetingDailog";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
  const navigate = useNavigate();
  const [dailogOpen, setDailogOpen] = useState(false);
  const [confirmDailogOpen, setConfirmDailogOpen] = useState(false);
  const [openFinishDailog, setopenFinishDailog] = useState(false);
  const [responseId, setResponseId] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [dialogOpen, setdialogOpen] = useState(false);
  const [joinDailogOpen, setJoinDailogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [curencyCodeValue, setCurencyCodeValue] = useState("");
  const [countrySymbal, setCountrySymbal] = useState("");
  // sign up dailog open first
  const signUpHandle = () => {
    setDailogOpen(!dailogOpen);
  };

  // confirm dailog open
  const continueHandle = () => {
    setConfirmDailogOpen(!confirmDailogOpen);
    setDailogOpen(false);
  };
  const otpId = (id, phoneNo, curencyCode, countryValue) => {
    setResponseId(id);
    setphoneNumber(phoneNo);
    setCurencyCodeValue(curencyCode);
    setCountrySymbal(countryValue);
  };

  // confirm button handle
  const VerifiyHandle = () => {
    setopenFinishDailog(!openFinishDailog);
    setConfirmDailogOpen(false);
    setDailogOpen(false);
  };
  // finish handle
  const finishHandle = () => {
    setopenFinishDailog(false);
    setdialogOpen(true);
  };
  // ok button handle
  const messagePopUpClose = () => {
    setdialogOpen(false);
    window.open("https://liveweb.skoolfirst.com/");
  };
  // join button clcik
  const joinButtonHandle = () => {
    setJoinDailogOpen(!joinDailogOpen);
  };
  // error otp mesage------
  const ErrorMessageHandle = () => {
    setErrorMessage(!errorMessage);
    setConfirmDailogOpen(false);
  };
  // ok button handle
  const errorMessageOkHandle = () => {
    setErrorMessage(false);
  };
  return (
    <div style={{ backgroundColor: "rgb(242, 242, 242)" }}>
      <div className="common_div_padding">
        <div className="best_school_heading">
          <h2 className="common_h2">Pricing</h2>
        </div>
        <div className="paddingTopNewFeature">
          {/* <Grid className="common_main_grid ">
            <Grid md={12}>
              <div className="pricing_padding_both">
                <Card className="pricing_white_card_shadoww p-5">
                  Please contact us at our [loremipsum@skoolfrist.com] or Schedule a meeting to
                  discuss on the pricing structured for your institution
                </Card>
              </div>
            </Grid>
          </Grid> */}

          <Grid className="common_main_grid ">
            <Grid md={2}></Grid>
            <Grid md={4}>
              <div className="shcedlue_meeting_padding_both">
                <div className="prcing_valur_right_set">
                  <div>
                    <Card className="pricing_blue_card_shadow">
                      <div>
                        <div className="Prcing_card_center">
                          <h3>Skoolfirst TeachOnline</h3>

                          <p>
                            Our package caters for all online teaching purpose
                            and can manage multiple students.
                          </p>
                          <div className="pricing_button_padding">
                            <button
                              onClick={() => {
                                // signUpHandle();
                                navigate("/CreateTeacherAccount");
                              }}
                            >
                              Sign Up
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid md={4}>
              {" "}
              <div className="shcedlue_meeting_padding_both">
                <Card className="pricing_white_card_shadow">
                  <div className="Prcing_card_center">
                    <h3>Skoolfirst Enterprise</h3>
                    {/* <div>
                      <span className="prcing_amout">10 Rs</span>
                      <span className="pricing_year"> /Student </span>
                    </div>
                    <span style={{ fontSize: 12 }}>
                      * Comes with 1 month free trial
                    </span>
                    <div className="pricing_hr">
                      <hr />
                    </div> */}
                    <p>
                      Our package caters for medium and large institutions like
                      schools, tuition centers having multiple faculty, academic
                      session, and simplify the administrative process
                    </p>
                    <div className="pricing_button_padding">
                      <button
                        onClick={() => {
                          joinButtonHandle();
                        }}
                      >
                        Contact Us
                      </button>
                    </div>
                  </div>
                </Card>
              </div>
            </Grid>
            <Grid md={2}></Grid>
          </Grid>
        </div>
      </div>
      {/* -------first dailog---------- */}
      {dailogOpen && (
        <SignUpDailog
          open={signUpHandle}
          continueHandle={continueHandle}
          otpId={otpId}
          VerifiyHandle={VerifiyHandle}
        />
      )}
      {/* -------second dailog--------- */}
      {confirmDailogOpen && (
        <ConfirmNumber
          open={continueHandle}
          VerifiyHandle={VerifiyHandle}
          responseId={responseId}
          phoneNumber={phoneNumber}
          ErrorMessageHandle={ErrorMessageHandle}
        />
      )}
      {/* -------third dailog---------- */}
      {openFinishDailog && (
        <FinishSignUp
          open={VerifiyHandle}
          finishHandle={finishHandle}
          phoneNumber={phoneNumber}
          curencyCodeValue={curencyCodeValue}
          countrySymbal={countrySymbal}
        />
      )}
      {/* ---------pop message----------- */}
      {dialogOpen && (
        <MessagePopUp
          dialogOpen={dialogOpen}
          messagePopUpClose={messagePopUpClose}
        >
          <div className="contentCenter">
            <div>
              <CheckCircleIcon className="iconFontSize" />
            </div>
            <div>
              <p>Congratulations !!</p>
              <p>Please check your Email for Login ID and Password.</p>
            </div>
            <div>
              <button
                className="buttonColorWidth"
                onClick={() => {
                  messagePopUpClose();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </MessagePopUp>
      )}
      {/* ---------otp message error */}
      {errorMessage && (
        <MessagePopUp
          dialogOpen={ErrorMessageHandle}
          messagePopUpClose={errorMessageOkHandle}
        >
          <div className="contentCenter">
            <div>
              <HighlightOffIcon className="iconFontSizeClose" />
            </div>
            <div>
              <p>
                You have exceeded max number of tries. Please contact us at
                <a href="contact@epalsoftware.com" target="_blank">
                  {" "}
                  contact@epalsoftware.com
                </a>
                .
              </p>
            </div>
            <div>
              <button
                className="buttonColorWidthError"
                onClick={() => {
                  errorMessageOkHandle();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </MessagePopUp>
      )}
      {/*---------- join Dailog---------- */}
      {joinDailogOpen && <JoinMeetingDailog open={joinButtonHandle} />}
    </div>
  );
};

export default Pricing;
