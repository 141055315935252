import React from "react";
import HOC from "../HOC/HOC";
import "./privacy_policy.css";
import Card from "@mui/material/Card";
const privacy_policy = () => {
  return (
    <div className="privacy_mian_div">
      <div className="privacy_padding_both">
        <Card className="privacy_card_shadow p-5">
          <div className="privacy_policy_header">
            <h2>PRIVACY POLICY</h2>
            <p>
              This Website “Site” is owned and operated by EPAL Software Pvt Ltd
              (referred as “Company”, “We” or “Us” hereafter). Due to the nature
              of services our Company is providing, we may possess, collect,
              process, store, certain information or data, as defined in this
              Privacy Policy (referred as “Policy” hereafter) from your end, as
              part of the process. This policy defines the manner in which your
              information is used, purpose for which the information is
              collected and usage, security policies of our organization in
              order to protect your data, important contacts to redress your
              data protection concerns, and other related policies. This Policy
              constitutes a binding Agreement between the Users (“Users”, “You”.
              “Your”) and the Company. This Privacy Policy governs all issues
              surrounding such collection of data and usage, either through our
              software or through our website.{" "}
            </p>
            <p>
              Your use of our Site and software is governed by these Privacy
              Policy along with other applicable policies, terms and agreements.
              You don’t need to sign a physical or electronic copy of this
              Policy. Accessing our Site or using any of the services offered by
              this Site or the software, shall imply that you have read and
              accepted these Policy before using or accessing this Site or using
              any of the offered services. All Users are advised to read these
              Policy carefully before accessing the Site or using its services.
              A User should cease and desist from accessing this Site or using
              any its services in case, the User does not agree to these Policy.
              This Privacy Policy is drafted and shall be construed, as per
              provisions of the Information Technology (Reasonable security
              practices and procedures and sensitive personal data or
              information) Rules, 2011 read with the Information Technology Act,
              2000.
            </p>
          </div>
          {/* para content start */}
          <div className="terms_of_heading_data">
            <h3>1) Definitions</h3>
            <ol type="a">
              <li>
                “Customer” shall refer to any person who has purchased the
                Software by paying the license or subscription fee.
              </li>
              <li>
                “Services” shall refer to Software as a Service (“SAAS”),
                offered by the Company to its Users in relation to its software.
              </li>
              <li>
                “Software” shall refer to the School management software
                designed, developed and owned by the Company and as described in
                the Site intended to provide School administration and
                management solutions to organizations.
              </li>
              <li>
                "Password" means a secret word or phrase or code or passphrase
                or secret key, or encryption or decryption keys that one uses to
                gain admittance or access to information
              </li>
              <li>
                "Personal information" means any information that relates to a
                natural person, which, either directly or indirectly, in
                combination with other information available is capable of
                identifying you.
              </li>
              <li>
                “User” shall refer to any person who accesses this Site or uses
                any of its services or subscribes or purchases any software
                license.
              </li>
              <li>“Website” shall refer to liveweb.skoolfirst.com.</li>
            </ol>
          </div>
          <div className="terms_of_heading_data">
            <h3>2) When information is collected</h3>
            <p>
              The Company collects information at different stages from its
              users and customers in the following ways:
            </p>
            <ol type="A">
              <li>
                When the User accesses the website or navigates through the
                website
              </li>
              <li>
                When a User registers in the platform and creates an account
                through the website
              </li>
              <li>
                When a User contacts us for getting certain information or help
                associated with our software
              </li>
              <li>
                When a Customer having a valid license for using the software,
                inputs, provides, uploads information in the software for
                storing it and When the User subscribes to our newsletter to get
                updates about our software, other products and developments
              </li>
              <li>
                When the customer provides any feedback, complaint, query
                relating to the software
              </li>
            </ol>
          </div>
          <div className="terms_of_heading_data">
            <h3>3) Types of data collected</h3>
            <p>
              The Company collects, handles, stores, and deals with different
              types of data from its Users through its Website and Software,
              which are listed below:
            </p>
            <div>
              <div className="privacy_strong_padding">
                <strong>a) Personal data</strong>
              </div>
              <div>
                <p>
                  The Company stores, handles and processes, the following
                  Personal data through its software, when such information is
                  provided and saved by the Customer in the software, while
                  using the software:
                </p>
                <p>Name of individual</p>
                <p>Address</p>
                <p>Email Id</p>
                <p>Gender</p>
                <p>Date of Birth</p>
                <p>Spouse Name</p>
                <p> Mother's Name </p>
                <p>Father's Name</p>
                <p> Legal guardian Name </p>
                <p>Passport details</p>
                <p>PAN</p>
                <p>Aadhaar Number</p>
                <p>Legal guardians name</p>
                <p className="pt-2">
                  The following information is further collected from the Users,
                  through the Website, when the User uses any form in the
                  website, to contact us:
                </p>
                <p>Email Id</p>
                <p>Name of institution or Organization</p>
                <p>Name of the individual </p>
                <p>Phone Number</p>
              </div>
            </div>
            <div>
              <div className="privacy_strong_padding">
                <strong>b) Cookies Data</strong>
              </div>

              <p>
                We use cookies for tracking the activity taking place within our
                website and provide better user experience. Cookies are files
                with small pieces of data, which are used to identify your
                computer, when you are using a computer network. Cookies can be
                used to identify specific users and improve user experience.
                Cookies can be sent through browser and stored on your device.
                You can choose to accept or reject cookies by changing the
                cookies settings. In case, you choose to reject all cookies you
                may not be able to use some of our services.
              </p>
            </div>

            <div>
              <div className="privacy_strong_padding">
                <strong>c) Location data</strong>
              </div>
              <p>
                We may collect data regarding your location. We use this data
                for the security of our website and to improve our services.
              </p>
            </div>
            <div>
              <div className="privacy_strong_padding">
                <strong>d) Usage Data</strong>
              </div>
              <p>
                Every time you access our website using your browser, your
                browser sends information to us. Such information may include,
                your internet protocol address, browser type, browser version,
                pages you visit, your device id, your operating system, etc. We
                collect such information for our own internal purposes.
              </p>
            </div>
          </div>
          <div className="terms_of_heading_data">
            <h3>4) Purposes for which Information is collected and usage</h3>
            <p>
              The Information is collected from you for the following purposes:
            </p>
            <ol type="i">
              <li>
                To provide the services, functionality, utility, associated with
                the software and website to the Users
              </li>
              <li>
                To identify and prevent technical issues, defects in
                functionality, associated with the software and website and to
                solve such technical issues and defects
              </li>
              <li>
                To track and identify, customer preferences, and areas where
                development can be made to improve user experience
              </li>
              <li>
                To develop and improve the software, products and website in to
                generate better user experience
              </li>
              <li>
                To promote the software, and other products of the Company
              </li>
              <li>
                To provide solutions to any queries or questions, regarding how
                the software works, receiving any suggestion in areas where the
                software can be improved
              </li>
              <li>
                To verify your identify in order to prevent any illegal use of
                the software or fraud or unauthorized usage
              </li>
              <li>
                To protect our devices, server and software from any cyber
                attacks
              </li>
              <li>
                To inform users about any developments, updates associated with
                the software or about new products or special offers or any
                change relating terms and conditions associated with the
                software or website
              </li>
              <li>
                To inform Users about any change in company policies and
                associated legal documentation or contracts
              </li>
              <li>
                To inform Users about transaction related information like,
                confirmation of subscription, welcome letters, renewal reminders
              </li>
              <li>
                To collect the usage data, traffic within the website, usage
                pattern within the website, and this data may be shared with
                third parties, however, no personally identifiable information
                is provided to such third parties, as information is transmitted
                as a whole and not specifically for any user
              </li>
              <li>
                To inform Users details of their subscription, including renewal
                date, subscription end date, and renewal intimation before end
                of subscription
              </li>
              <li>
                To enforce our rights associated with the contract of purchase,
                or other applicable contracts and use of the software, legally
                against you
              </li>
              <li>To provide you with customer support</li>
              <li>
                To receive feedback from users about the software and share or
                use such information
              </li>
              <li>To comply with applicable laws, regulations and policies</li>
              <li>
                To exchange contractual documents, notices, legal documents
                associated with subscription of the software, termination of
                subscription, breaches of any terms and conditions associated
                with the software license or any applicable agreements, etc.
              </li>
              <li>
                To send marketing and promotional materials to our users or
                other materials which might interest our users
              </li>
              <li>
                To display your logo or personal information or comments, in our
                website, in case you have purchased the software and/or provided
                feedback regarding our software
              </li>
              <li>For any other purpose, with your consent</li>
            </ol>
          </div>
          <div className="terms_of_heading_data">
            <h3>5) Storage of Data</h3>
            <p>
              The data collected from you shall be stored in servers or devices
              located in the Republic of India or in any other country which
              follows the same level of data protection as provided under
              theInformation Technology (Reasonable security practices and
              procedures and sensitive personal data or information) Rules, 2011
            </p>
          </div>

          <div className="terms_of_heading_data">
            <h3>6) Security Policy</h3>
            <p>
              We have implemented standard security policies and safeguards,
              practices and in order to keep your information safe and prevent
              data breaches. Our software is further protected through various
              security measures like, requirement of credentials at various
              levels while using the software, data encryption, disclosure or
              your information to only those employees who may need to process
              your information for carrying out administrative activities,
              firewall protection, in order to protect you from unwanted and
              unauthorized data breaches. We have additionally incorporated data
              protection policies within our organization in order to safeguard
              your information. We use third party payment gateway who may
              collect your financial information and use of your financial data
              shall be governed by the privacy policy of such third party
              service providers. We advise you to review their privacy policy
              carefully before using them. No security measure is hundred
              percent safe and there is still chance of third party cyberattacks
              and data breaches. You should have installed standard antivirus
              software in all your devices, educate your employees about
              maintaining confidentiality and following standard procedures to
              prevent data breaches within your organization. We are not liable
              to you for any data breaches taking place due to third parties or
              in the hands of third party service providers or due to your
              account.
            </p>
          </div>
          <div className="terms_of_heading_data">
            <h3>7) Amendment</h3>
            <p>
              This Privacy Policy can be amended or changed, by giving notice to
              the Users. Changing the Privacy Policy and putting the amended
              Privacy Policy in the website shall be considered sufficient
              notice to the Users and shall not require any other separate
              notice. The Users are advised to visit this page from time to time
              to stay updated and notified regarding any changes to the Privacy
              Policy. The Company reserves the right to amend the Privacy Policy
              at any time. The Company may at its discretion inform the Users by
              email of any changes made to this Privacy or provide a
              notification about the same at any conspicuous place in the
              website. Your use of this website or the software or any products
              provided by the Company, after any change or amendment to this
              Privacy Policy, shall mean that you have accepted such change. In
              case you don’t agree to any change or manner in which your
              information is being used, you may stop using the website, or
              software or any other products.
            </p>
          </div>
          <div className="terms_of_heading_data">
            <h3>8) Third party Links</h3>
            <p>
              Our site may contain links to third party websites, mobile
              applications and apps. You must keep yourself informed about the
              privacy policies and practices of such websites, mobile
              applications, and apps before using them. We don’t hold any
              responsibility about the privacy policies and practices of such
              sites and applications, as they are beyond our control, and as
              such shall not be held liable for any breaches of privacy or
              personal information or sensitive information, while you are using
              such sites.
            </p>
          </div>
          <div className="terms_of_heading_data">
            <h3>9) Grievance Officer</h3>
            <p>
              {" "}
              In accordance with Information Technology Act 2000 and rules made
              there under, the contact details of the Grievance Officer are
              provided below:
            </p>
            <p>Email Address : contact@epalsoftware.com</p>
            <p>
              Address : No. 334, 4th Floor, 27th Main Road, 2nd Sector, HSR
              Layout, Bangalore Karnataka 560102
            </p>
          </div>
          <div className="terms_of_heading_data">
            <h3>10) Contact us</h3>
            <p>
              {" "}
              If you have questions regarding this Privacy Policy you may
              contact us at contact@epalsoftware.com.
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default HOC(privacy_policy);
