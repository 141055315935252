import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import axios from "../../services/axios";
import ReCAPTCHA from "react-google-recaptcha";
import { DialogActions } from "@mui/material";
import Loader from "../Loader/Loader";
import { baseUrl } from "../../services";
const config = require("../../config/default.json");

const JoinMeetingDailog = ({ open }) => {
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState(false);

  const [recaptchErr, setRecaptchaErr] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [messageValue, setMessageValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const SheduleMeeting = async () => {
    try {
      if (fullName === "") {
        setFullNameError(true);
        return;
      }
      if (phoneNumber === "") {
        setPhoneNumberError(true);
        return;
      }
      const emailReg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailId.match(emailReg)) {
        setEmailIdError(true);
        return;
      }

      // if (!recaptchaValue) {
      //   setRecaptchaErr(true);
      //   return;
      // }
      setIsLoading(true);
      const { data } = await axios.post(baseUrl + "/enquirySchool", {
        email: emailId,
        phone: phoneNumber,
        date: new Date(),
        name: fullName,
        messageValue: messageValue,
      });
      if (data) {
        alert(data);
        setIsLoading(false);
      }
      console.log("data", data);
      open();
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  return (
    <div>
      <Dialog open={open} fullWidth={false} maxWidth={"sm"}>
        <DialogTitle>
          <div
            className="closeIconRight"
            onClick={() => {
              open();
            }}
          >
            <ClearIcon />
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <TextField
              id="standard-basic"
              label="Full name"
              variant="standard"
              className="common_textfield_width"
              value={fullName}
              onChange={(e) => {
                setFullName(e.target.value);
                setFullNameError(false);
              }}
            />
            {fullNameError && (
              <span className="blank_error">Enter full name</span>
            )}
          </div>
          <div className="schedule_padding_filed">
            <TextField
              id="standard-basic"
              label="Phone number"
              variant="standard"
              className="common_textfield_width"
              value={phoneNumber}
              onChange={(e) => {
                if (isNaN(e.target.value)) {
                  return;
                }
                setPhoneNumber(e.target.value);
                setPhoneNumberError(false);
              }}
            />
            {phoneNumberError && (
              <span className="blank_error">Enter phone number</span>
            )}
          </div>
          <div className="schedule_padding_filed">
            <TextField
              id="standard-basic"
              label="Email Address"
              variant="standard"
              className="common_textfield_width"
              value={emailId}
              onChange={(e) => {
                setEmailId(e.target.value);
                setEmailIdError(false);
              }}
            />
            {emailIdError && (
              <span className="blank_error">Enter Email Id</span>
            )}
          </div>
          <div className="schedule_padding_filed">
            <textarea
              rows="4"
              placeholder="message here"
              className="form-control"
              value={messageValue}
              onChange={(e) => {
                setMessageValue(e.target.value);
              }}
            ></textarea>
          </div>

          <div className="mt-3 my_recaptcha">
            <p>
              <ReCAPTCHA
                sitekey={config.app.siteKey}
                //   onChange={callback}
                onChange={(code) => {
                  if (recaptchErr) setRecaptchaErr(false);
                  setRecaptchaValue(code);
                }}
              />
            </p>
            {recaptchErr && (
              <p className="text-danger">Please validate recaptcha</p>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div>
            <button
              className="common_button"
              onClick={() => {
                SheduleMeeting();
              }}
            >
              Contact Me
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Loader loading={isLoading} />
    </div>
  );
};

export default JoinMeetingDailog;
