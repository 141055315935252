import WebsiteHomepage from "./Components/WebsiteHomepage/WebsiteHomepage";
import TermsAndCondition from "./Components/TermsAndCondition/TermsAndCondition";
import PrivacyPolicy from "./Components/Privacy_policy/privacy_policy";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { baseUrl } from "./services";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import UserAgreement from "./Components/UserAgreement/UserAgreement";
import Blogs from "./Components/Blogs/Blogs";
import BlogsDetails from "./Components/Blogs/BlogsDetails";
import SelectTutors from "./Components/FindTutors/SelectTutors";
import PtTeacherForm from "./Components/PtTeacherForm/PtTeacherForm";
import CreateTeacherAccount from "./Components/SignUp/CreateTeacherAccount";
import OtpVerifyPage from "./Components/SignUp/OtpVerifyPage";
import StudentSelectTeacher from "./Components/StudentSelectTeacher/StudentSelectTeacher";
import CreateStudentAccount from "./Components/SignUp/CreateStudentAccount";
import StudentOtpVerifyPage from "./Components/SignUp/StudentOtpVerifyPage";
import PtTeacherHowItWork from "./Components/HowItWork/PtTeacherHowItWork";
import PtStudentHowItWork from "./Components/HowItWork/PtStudentHowItWork";
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  // --------to update data with change--------
  const defaultOptions = {
    watchQuery: { fetchPolicy: "no-cache", errorPolicy: "ignore" },
    query: { fetchPolicy: "no-cache", errorPolicy: "all" },
  };
  const client = new ApolloClient({
    uri: `${baseUrl}/graphql`,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });

  return (
    <ApolloProvider client={client}>
      <Routes>
        <Route exact path="/" element={<WebsiteHomepage />} />
        <Route
          exact
          path="/TermsAndCondition"
          element={<TermsAndCondition />}
        />
        <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route exact path="/endUserAggreement" element={<UserAgreement />} />
        <Route exact path="/Blog" element={<Blogs />} />
        <Route exact path="/Blog/:title" element={<BlogsDetails />} />
        <Route
          exact
          path="/TutorsList"
          element={<SelectTutors navigate={navigate} location={location} />}
        />
        <Route
          exact
          path="/CreateTeacherAccount"
          element={
            <CreateTeacherAccount navigate={navigate} location={location} />
          }
        />
        <Route
          exact
          path="/OtpVerifyPage"
          element={<OtpVerifyPage navigate={navigate} location={location} />}
        />
        <Route
          exact
          path="/PtTeacherForm"
          element={<PtTeacherForm navigate={navigate} location={location} />}
        />
        <Route
          exact
          path="/StudentSelectTeacher"
          element={
            <StudentSelectTeacher navigate={navigate} location={location} />
          }
        />
        <Route
          exact
          path="/CreateStudentAccount"
          element={
            <CreateStudentAccount navigate={navigate} location={location} />
          }
        />
        <Route
          exact
          path="/StudentOtpVerifyPage"
          element={
            <StudentOtpVerifyPage navigate={navigate} location={location} />
          }
        />
        <Route
          exact
          path="/PtTeacherHowItWork"
          element={
            <PtTeacherHowItWork navigate={navigate} location={location} />
          }
        />
        <Route
          exact
          path="/PtStudentHowItWork"
          element={
            <PtStudentHowItWork navigate={navigate} location={location} />
          }
        />
      </Routes>
    </ApolloProvider>
  );
}

export default App;
