import React, { useState, useEffect } from "react";
import HOC from "../HOC/HOC";
import "./SelectTutors.css";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ClearIcon from "@mui/icons-material/Clear";
import DialogActions from "@mui/material/DialogActions";
import Swal from "sweetalert2";
import { useApolloClient } from "@apollo/client";
import { findPrivateTeachers } from "../../services";
import Loader from "../Loader/Loader";
import { getFullName, getWeekDay } from "../../Utils/validation";
import NoResult from "../Images/NoResult.jpg";
import FindTutorDailog from "../WebsiteHomepage/FindTutorDailog";

const SelectTutors = (props) => {
  const { board, classValue, languages, subjects } =
    props.location?.state?.data;
  const client = useApolloClient();
  const [selectTutorsDailogOpen, setSelectTutorsDailogOpen] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [selectInstituteId, setSelectInstituteId] = useState("");
  const [selectIndex, setSelectIndex] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [messageShow, setMessageShow] = useState(false);
  const [tutorsDailogOpen, setTutorsDailogOpen] = useState(false);

  useEffect(() => {
    getTeacherList();
  }, []);
  useEffect(() => {
    getTeacherList();
    setTutorsDailogOpen(false);
  }, [props.location?.state?.data]);

  const getTeacherList = async () => {
    setIsloading(true);
    const { data } = await client.query({
      query: findPrivateTeachers,
      variables: {
        subject: subjects || "",
        class: classValue || "",
        board: board || "",
        language: languages || "",
      },
    });

    if (data.findPrivateTeachers) {
      if (data.findPrivateTeachers.length === 0) {
        setMessageShow(true);
      } else {
        setProfileData(data.findPrivateTeachers);
        setMessageShow(false);
      }
    }
    setIsloading(false);
  };

  // const selectCheckBoxHamdle = (index, index3, value, userId) => {
  //   let temp = profileData;
  //   temp[index].teacher_availabilities[index3].isSelected = !value;
  //   setProfileData(temp);
  // };
  const selectTutosHandle = (index, item) => {
    let tempValue = {
      row: item,
    };
    props.navigate("/CreateStudentAccount", {
      state: {
        data: tempValue,
      },
    });
    // if (+selectInstituteId === +item.id) {
    //   let tempData = [];

    //   let temp = profileData;
    //   temp[index].teacher_availabilities.map((row) => {
    //     if (row.isSelected === true) {
    //       tempData.push({
    //         day: row.day,
    //         time: row.start_time,
    //       });
    //     }
    //   });

    //   let tempValue = {
    //     dayTime: tempData,
    //     row: item,
    //   };
    //   props.navigate("/CreateStudentAccount", {
    //     state: {
    //       data: tempValue,
    //     },
    //   });
    // } else {
    //   Swal.fire({
    //     title: "Choose Availability Time ",
    //   });
    // }
  };
  const findTutorsHandle = () => {
    setTutorsDailogOpen(!tutorsDailogOpen);
  };
  return (
    <div className="website_home_main_div" style={{ background: "#f3f9ff" }}>
      <div className="SelectTuroPadding">
        {/* mam section */}
        {profileData.map((row, index) => (
          <div className="findTutorsMarginSapce" key={index}>
            <Grid className="common_main_grid">
              <Grid item md={6}>
                <Grid className="common_main_grid">
                  {/* <Grid item md={3}>
                    <div className="fintTutorsPadding imageCenter">
                      <img
                        src={row?.image}
                        alt="no_image"
                        width={160}
                        height={160}
                      />
                    </div>
                  </Grid> */}
                  <Grid item md={12}>
                    <div className="fintTutorsPadding">
                      <div className="tutorsInfo">
                        <p>{getFullName(row?.employees[0].name)}</p>
                        <p>
                          <b>Subjects :</b>{" "}
                          {row.subjects &&
                            row.subjects.map((sub, index1) => (
                              <span key={index1} className="pr-1">
                                {sub.name}{" "}
                                {index1 === row.subjects.length - 1 ? "" : ","}
                              </span>
                            ))}
                        </p>
                        <p>
                          <b>Language Spoken : </b>{" "}
                          {row.languages &&
                            row.languages.map((lag, index2) => (
                              <span key={index2} className="pr-1">
                                {lag.name}{" "}
                                {index2 === row.languages.length - 1 ? "" : ","}
                              </span>
                            ))}
                        </p>
                        <p>
                          <b> Experience :</b>{" "}
                          {row?.employees[0]?.total_experience} years
                        </p>
                        <p>
                          <b> Session Fees :</b> {row?.teacher_pricing?.amount}{" "}
                          {row?.teacher_pricing?.currency_code} /Hr
                        </p>

                        {/* <p>
                          <b>Availability :</b>{" "}
                        </p>
                        <div className="avaliblityFlex">
                          {row.teacher_availabilities &&
                            row.teacher_availabilities.map((avb, index3) => (
                              <div
                                style={{ width: "33%", display: "flex" }}
                                key={index3}
                              >
                                <input
                                  style={{ marginLeft: "7%" }}
                                  class="form-check-input"
                                  type="checkbox"
                                  value={avb.isSelected}
                                  onChange={(e) => {
                                    setSelectInstituteId(row.id);
                                    selectCheckBoxHamdle(
                                      index,
                                      index3,
                                      avb.isSelected || false
                                      // row.id
                                    );
                                    setSelectIndex(index);
                                  }}
                                />
                                <p style={{ width: "80%" }}>
                                  {getWeekDay(avb.day, avb.start_time)}
                                </p>
                              </div>
                            ))}
                        </div> */}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={2}>
                <div className="buttonCenter">
                  <button
                    className="common_button "
                    onClick={() => {
                      selectTutosHandle(index, row);
                    }}
                  >
                    Select Tutor
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
        ))}
        {messageShow && (
          <div className="noDataMeesage">
            <img src={NoResult} />
            <p style={{ color: "black", fontWeight: "bold" }}>
              We cannot find tutor matching your criteria
            </p>
            <button
              className="common_button"
              onClick={() => {
                findTutorsHandle();
              }}
            >
              Find Tutor
            </button>
          </div>
        )}
        <Loader loading={isloading} />
      </div>
      {/* dailog section */}
      <Dialog
        open={selectTutorsDailogOpen}
        fullWidth={true}
        maxWidth={"sm"}
        className="selectTimeBorder"
      >
        <DialogTitle className="titleCenter">
          Select Time
          <span className="closeIconRight" style={{ float: "right" }}>
            <ClearIcon
              onClick={() => {
                setSelectTutorsDailogOpen(false);
              }}
            />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="selecttuotsTime">
            <p>Monday 8:00 PM</p>
            <p> Sunday 10:00 PM</p>
          </div>
        </DialogContent>
        <DialogActions>
          {" "}
          <button
            className="common_button "
            onClick={() => {
              //   SearchHandle();
            }}
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>
      {/* ---------find tutors dailog----- */}
      {tutorsDailogOpen && <FindTutorDailog open={findTutorsHandle} />}
    </div>
  );
};

export default HOC(SelectTutors);
