import React from "react";
import "./WebsiteHomepage.css";
import HOC from "../HOC/HOC";
import ScheduleMeeting from "./ScheduleMeeting";
import OurCustomar from "./OurCustomar";
import BestSchool from "./BestSchool";
import Faqs from "./Faqs";
import FeatureAnalyises from "./FeatureAnalyises";
import CustomizedTabs from "./CustomizedTabs";
import Pricing from "./Pricing";
import AboutUs from "./AboutUs";
import BringSchoolLevel from "./BringSchoolLevel";
import ContactUs from "./ContactUs";
import NewFeaturesData from "./NewFeaturesData";
import { Helmet } from "react-helmet";

const WebsiteHomepage = () => {
  return (
    <div className="website_home_main_div">
      <Helmet>
        <title>
          Online Classes or Student Fee Management System | SkoolFirst
        </title>
        <meta
          name="title"
          content="Online Classes or Student Fee Management System | SkoolFirst"
        />
        <meta
          name="keywords"
          content="Online Classes Management System, Student Fee Management System, Student Attendance Software, Best CRM Software For Schools, Student Assignment Management Software"
        />
        <meta
          name="description"
          content="Are you looking for the best CRM software or management systems for schools? We provide software that manages your Online Classes, Student Fee or Attendance, etc."
        />
      </Helmet>
      {/* -----meeting section --- */}
      <section id="home" className="common_padding_margin_top">
        <ScheduleMeeting />
      </section>
      <section>{/* <BringSchoolLevel /> */}</section>
      {/* -----fetaure count section--- */}
      {/* <section>
        <OurCustomar />
      </section> */}
      {/* -----BestSchool section --- */}
      <section>{/* <BestSchool /> */}</section>
      {/* -----feature section --- */}
      <section id="features" className="common_padding_margin_top">
        {/* <FeatureAnalyises /> */}
        {/* <CustomizedTabs /> */}
        <NewFeaturesData />
      </section>
      {/* -----About Us section --- */}
      {/* <section id="about_us" className="common_padding_margin_top">
        <AboutUs />
      </section> */}
      {/* -----FAQs section --- */}
      <section id="faq" className="common_padding_margin_top">
        <Faqs />
      </section>
      {/* ------Pricing section------- */}
      <section id="pricing" className="common_padding_margin_top">
        {" "}
        <Pricing />
      </section>
      {/* --------contact us---------- */}
      <section id="contact_us" className="common_padding_margin_top">
        <ContactUs />
      </section>
    </div>
  );
};

export default HOC(WebsiteHomepage);
