import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const MessagePopUp = ({ dialogOpen, messagePopUpClose, children }) => {
  console.log("childrens", children);
  return (
    <div>
      {/* -------dailog------- */}
      {dialogOpen && (
        <div className="dailogWidthHeight">
          <dialog open={true} className="dialogPosition">
            {children}
          </dialog>
        </div>
      )}
    </div>
  );
};

export default MessagePopUp;
