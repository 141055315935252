import React, { Fragment, Component } from "react";
import HomeFooter from "./HomeFooter";
import HomeHeader from "./HomeHeader";
export default  (Wcomponent) => {
  class HOC extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      return (
        <Fragment>
          <HomeHeader />
          <Wcomponent {...this.props} />
          <HomeFooter/>
        </Fragment>
        
      );
    }
  }

  return HOC;
};
