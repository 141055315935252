import { Store } from "react-notifications-component";

// show notification msz
export const showNotificationMsz = (message, messageType) => {
  console.log("message==", message, messageType);
  Store.addNotification({
    title: "",
    message: `${message}`,
    type: `${messageType}`,
    insert: "top-center",
    container: "top-center",
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};
export const pad = (n) => {
  return n < 10 ? "0" + n : n;
};
export const getMinAndSecfromDate = (date) => {
  let mydate = new Date(date);
  if (date) {
    var hours = mydate.getHours();
    var minutes = mydate.getMinutes();
    return pad(hours) + ":" + pad(minutes);
  } else {
    return "00:00";
  }
};
export const blankValidator = (validators, key) => {
  if (
    validators === undefined ||
    validators === "" ||
    validators === null ||
    validators == {} ||
    validators === [] ||
    validators == -1 ||
    validators == NaN
  ) {
    return false;
  }
  return true;
};
// to make first letter capital
export const getFullName = (nameObj) => {
  if (!blankValidator(nameObj)) {
    return "";
  }
  let name = "";
  if (blankValidator(nameObj.first_name)) {
    name = nameObj.first_name;
  }
  if (blankValidator(nameObj.middle_name)) {
    name = name + " " + nameObj.middle_name;
  }
  if (blankValidator(nameObj.last_name)) {
    name = name + " " + nameObj.last_name;
  }
  return name;
};

export const getWeekDay = (day, time) => {
  let dayTime = "";
  if (day === "0") {
    dayTime = "Sunday" + " " + time;
  }
  if (day === "1") {
    dayTime = "Monday" + " " + time;
  }
  if (day === "2") {
    dayTime = "Tuesday" + " " + time;
  }
  if (day === "3") {
    dayTime = "Wednesday" + " " + time;
  }

  if (day === "4") {
    dayTime = "Thursday" + " " + time;
  }
  if (day === "5") {
    dayTime = "Friday" + " " + time;
  }
  if (day === "6") {
    dayTime = "Saturday" + " " + time;
  }
  return dayTime;
  // {avb.day === "1" && (
  //   <p style={{ width: "80%" }}>
  //     Monday {avb.start_time}
  //   </p>
  // )}
  // {avb.day === "2" && (
  //   <p style={{ width: "80%" }}>
  //     Tuesday {avb.start_time}
  //   </p>
  // )}
  // {avb.day === "3" && (
  //   <p style={{ width: "80%" }}>
  //     Wednesday {avb.start_time}
  //   </p>
  // )}
  // {avb.day === "4" && (
  //   <p style={{ width: "80%" }}>
  //     Thursday {avb.start_time}
  //   </p>
  // )}
  // {avb.day === "5" && (
  //   <p style={{ width: "80%" }}>
  //     Friday {avb.start_time}
  //   </p>
  // )}
  // {avb.day === "6" && (
  //   <p style={{ width: "80%" }}>
  //     Saturday {avb.start_time}
  //   </p>
  // )}
};
