import React from "react";
import "./HOC.css";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

const year = new Date().getFullYear();
const HomeFooter = () => {
  const privacyPolicyHandle = () => {
    window.location = "/privacy_policy";
  };
  const TermsAndConditionHandle = () => {
    window.location = "/TermsAndCondition";
  };
  return (
    <div>
      <div className="">
        <AppBar position="static" className="home_footer_main_div">
          <Toolbar>
            <div className="home_footer_flex">
              {/* <p className="home_footer_font_size">Phone: +663456787</p> */}
              <p className="home_footer_font_size">
                Email: contact@epalsoftware.com
              </p>
              <p
                className="home_footer_font_size cursor"
                onClick={privacyPolicyHandle}
              >
                Privacy Policy
              </p>
              <p
                className="home_footer_font_size cursor"
                onClick={TermsAndConditionHandle}
              >
                Terms&Conditions
              </p>
              {/* <p className="home_footer_copy_right">
                Copyright © {year} Crafted with love
              </p> */}

              <div className="footer_icon">
                <TwitterIcon className="footer_icon_color" />
                <YouTubeIcon className="footer_icon_color" />
                <FacebookIcon className="footer_icon_color" />
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
};

export default HomeFooter;
