import React, { useState } from "react";
import "./SignUp.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ClearIcon from "@mui/icons-material/Clear";
import Swal from "sweetalert2";
import { SignupForPrivateTeacher } from "../../services";
import { useMutation } from "@apollo/react-hooks";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "../Loader/Loader";

const config = require("../../config/default.json");

const FinishSignUp = ({
  open,
  finishHandle,
  phoneNumber,
  curencyCodeValue,
  countrySymbal,
}) => {
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState(false);
  const [recaptchErr, setRecaptchaErr] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isLoaing, setIsLoaing] = useState(false);

  const [Signup_For_PrivateTeacher, {}] = useMutation(SignupForPrivateTeacher);

  // agree and continue handle
  const agreeAndConitueHandle = async () => {
    if (!firstName) {
      setFirstNameError(true);
      return;
    }
    if (!lastName) {
      setFirstNameError(true);
      return;
    }
    const emailReg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailId.match(emailReg)) {
      setEmailIdError(true);
      return;
    }
    setIsLoaing(true);
    try {
      const { data } = await Signup_For_PrivateTeacher({
        variables: {
          firstName: firstName,
          lastName: lastName,
          email: emailId,
          phoneNumber: phoneNumber,
          currency_code: curencyCodeValue,
          country_code: countrySymbal,
        },
      });
      if (data) {
        setIsLoaing(false);
        if (data.signupForPrivateTeacher.errorCode === 1) {
          Swal.fire({
            title: data.signupForPrivateTeacher.message,
          });
        } else {
          finishHandle();
        }
      }
    } catch (error) {
      setIsLoaing(false);
    }
  };

  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle className="titleCenter">
          <span
            className="iconsLeftSet"
            onClick={() => {
              open();
            }}
          >
            <ClearIcon />
          </span>
          Finish signing up
        </DialogTitle>
        <DialogContent>
          <div className="overSrcollpostion">
            <div>
              <input
                type="text"
                placeholder="First name"
                className="inputForm"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFirstNameError(false);
                }}
              />
              {/* {firstNameError && (
                <span className="blank_error">Enter First Name</span>
              )} */}
            </div>
            <div>
              <input
                type="text"
                placeholder="Last name"
                className="inputFormlastName"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setFirstNameError(false);
                }}
              />
              {firstNameError && (
                <span className="blank_error">
                  Enter First Name and Last Name
                </span>
              )}
            </div>
            <div className="EmailPadding">
              <input
                type="text"
                placeholder="Email"
                className="inputFormWidth"
                value={emailId}
                onChange={(e) => {
                  setEmailId(e.target.value);
                  setEmailIdError(false);
                }}
              />
              {emailIdError && (
                <span className="blank_error">Enter Email Id</span>
              )}
            </div>
            <div className="recaptchaPadding">
              {" "}
              <ReCAPTCHA
                sitekey={config.app.siteKey}
                // onChange={callback}
                onChange={(code) => {
                  if (recaptchErr) setRecaptchaErr(false);
                  setRecaptchaValue(code);
                }}
              />
            </div>
            <div>
              <button
                disabled={isLoaing}
                className="common_button btnWidth"
                onClick={() => {
                  agreeAndConitueHandle();
                }}
              >
                Agree and Continue
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Loader loading={isLoaing} />
    </div>
  );
};

export default FinishSignUp;
