import React from "react";
import HOC from "../HOC/HOC";
import "./HowitWork.css";
// import ForwardIcon from "@mui/icons-material/Forward";
import EastIcon from "@mui/icons-material/East";
import SouthIcon from "@mui/icons-material/South";
import { useNavigate } from "react-router-dom";

const PtTeacherHowItWork = () => {
  const navigate = useNavigate();
  return (
    <div className="howItWorkMainDiv">
      <div className="howItWorkHeading">
        <h2>Teacher SignUp - how it works</h2>
      </div>
      <div className="howitpaddingBoth">
        {/* card section */}
        <div className="cardFlexCenter">
          {/* first card */}
          <div className="cardWidth">
            <div className="d-flex">
              <div className="arrowCard">
                <p className="headingCenter">Tutor Registration </p>
                <div className="paracaedMargin">
                  <p>
                    Register as a Tutor with your Phone number, email, Subjects,
                    Fees and other details.
                  </p>
                  <div className="bottomArrow">
                    <SouthIcon />
                  </div>
                  <p>
                    Admin verifies your information and Approve or Reject your
                    registration of tutor account.
                  </p>
                  <div className="bottomArrow">
                    <SouthIcon />
                  </div>
                  <p>
                    You will receive email/sms about your account once your
                    account review is completed.
                  </p>
                </div>
              </div>
              <div className="arrrowFixCenter">
                <EastIcon />
              </div>
            </div>
          </div>
          {/* second card */}

          <div className="cardWidth">
            <div className="d-flex">
              <div className="arrowCard">
                <p className="headingCenter">Start your online class </p>
                <div className="paracaedMargin">
                  <p>
                    Your profile is displayed in the search result to the
                    Students, if the search matches with your profile. Student
                    will request for a class.
                  </p>
                  <div className="bottomArrow">
                    <SouthIcon />
                  </div>
                  <p>
                    You will receive an email about your new student request.
                    You can login and decide to accept or reject the student
                    request in the homepage.
                  </p>
                  <div className="bottomArrow">
                    <SouthIcon />
                  </div>
                  <p>
                    Your scheduled classes for today will be shown in the
                    homepage. You can start online class by clicking on the link
                    there.
                  </p>
                </div>
              </div>
              <div className="arrrowFixCenter">
                <EastIcon />
              </div>
            </div>
          </div>

          {/* third card */}
          <div style={{ width: "29%" }}>
            <div className="d-flex">
              <div className="arrowCard">
                <p className="headingCenter">Your payments </p>
                <div className="paracaedMargin Cardcenter">
                  <p>
                    You will be paid every month by 7th, based on how many
                    classes you have conducted in the last month . Your Fees
                    will be transferred to the same bank account as mentioned by
                    you during the Registration Form.
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <button
                    className="common_button buttonTopSetting"
                    onClick={() => {
                      // signUpHandle();
                      navigate("/CreateTeacherAccount");
                    }}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
              {/* <div className="arrrowFixCenter">
              <div className=" long-arrow-right"> </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(PtTeacherHowItWork);
