import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ClearIcon from "@mui/icons-material/Clear";
import Multiselect from "multiselect-react-dropdown";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate, useLocation } from "react-router-dom";

const FindTutorDailog = ({ open }) => {
  const navigate = useNavigate();
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSubjectError, setSelectedSubjectError] = useState(false);
  const [selectedlanguage, setSelectedlanguage] = useState("");
  const [selectedlanguageError, setSelectedlanguageError] = useState(false);
  const [boardValue, setBoardValue] = useState("");
  const [boardValueError, setBoardValueError] = useState(false);
  const [classValue, setClassValue] = useState("");
  const [classValueError, setClassValueError] = useState(false);
  const boardsListArray = [
    {
      id: 1,
      name: "Central Board of Secondary Education (CBSE)",
    },
    {
      id: 2,
      name: "Council for The Indian School Certificate Examinations (ICSE)",
    },
    {
      id: 3,
      name: "International Baccalaureate (IB)",
    },
    {
      id: 4,
      name: "IGCSE",
    },
    {
      id: 5,
      name: "National Institute of Open Schooling (NIOS)",
    },
    {
      id: 6,
      name: "Aligarh Muslim University, Aligarh",
    },
    {
      id: 7,
      name: "Andhra Pradesh Open School Society",
    },
    {
      id: 8,
      name: "Assam Sanskrit Board",
    },
    {
      id: 9,
      name: "Banasthali Vidyapith, Rajasthan",
    },

    {
      id: 10,
      name: "Bihar Board of Open Schooling And Examination",
    },
    {
      id: 11,
      name: "Bihar Sanskrit Shiksha Board",
    },
    {
      id: 12,
      name: "Bihar School Examination Board",
    },
    {
      id: 13,
      name: "Bihar State Madrasa Education Board",
    },
    {
      id: 14,
      name: "Board of High School And Intermediate Education Uttar Pradesh",
    },
    {
      id: 15,
      name: "Board of School Education Haryana",
    },
    {
      id: 16,
      name: "Board of Secondary Education Andhra Pradesh",
    },
    {
      id: 17,
      name: "Board of Secondary Education Assam",
    },
    {
      id: 18,
      name: "Board of Secondary Education, Odisha",
    },
    {
      id: 19,
      name: "Board of Secondary Education, Rajasthan",
    },
    {
      id: 20,
      name: "Chhatisgarh State Open School",
    },
    {
      id: 21,
      name: "Chhattisgarh Board of Secondary Education",
    },
    {
      id: 22,
      name: "Chhattisgarh Sanskrit Board",
    },
    {
      id: 23,
      name: "Directorate of Army Education, New Delhi",
    },
    {
      id: 24,
      name: "Directorate of Government Examinations, Tamil Nadu",
    },
    {
      id: 25,
      name: "Goa Board of Secondary And Higher Secondary Education",
    },
    {
      id: 26,
      name: "Gujarat Secondary And Higher Secondary Education Board",
    },
    {
      id: 27,
      name: "Gurukul Kangri Vishwavidyalaya, Haridwar",
    },
    {
      id: 28,
      name: "Haryana Open School",
    },
    {
      id: 29,
      name: "Himachal Pradesh Board of School Education",
    },
    {
      id: 30,
      name: "Himachal Pradesh State Open School",
    },

    {
      id: 31,
      name: "Jamia Miliya Hamdard University",
    },
    {
      id: 32,
      name: "Jamia Miliya Islamia, New Delhi",
    },
    {
      id: 33,
      name: "Jammu and Kashmir State Board of School Education",
    },
    {
      id: 34,
      name: "Jammu and Kashmir State Open School",
    },
    {
      id: 35,
      name: "Jharkhand Academic Council Ranchi",
    },
    {
      id: 36,
      name: "Karnataka Secondary Education Examination Board",
    },
    {
      id: 37,
      name: "Kerala Board of Public Examinations",
    },
    {
      id: 38,
      name: "Madhya Pradesh Board of Secondary Education",
    },
    {
      id: 39,
      name: "Madhya Pradesh State Open School",
    },
    {
      id: 40,
      name: "Maharashtra State Board of Secondary And Higher Secondary Education",
    },
    {
      id: 41,
      name: "Manipur Board of Secondary Education",
    },
    {
      id: 42,
      name: "Meghalaya Board of School Education",
    },
    {
      id: 43,
      name: "Mizoram Board of School Education",
    },
    {
      id: 44,
      name: "Nagaland Board of School Education",
    },
    { id: 45, name: "Punjab School Education Board" },
    { id: 46, name: "Rajasthan State Open School" },
    { id: 47, name: "Rashtriya Sanskrit Sansthan, New Delhi" },
    {
      id: 48,
      name: "State Council for Open and Lifelong Education - Kerala",
    },
    { id: 49, name: "State Madrassa Education Board Assam" },
    { id: 50, name: "Tamil Nadu Board of Secondary Education" },
    { id: 51, name: "Tamilnadu Council for Open And Distance Learning" },
    { id: 52, name: "Tripura Board of Secondary Education" },
    { id: 53, name: "Uttarakhand Board of School Education" },
    { id: 54, name: "Uttrakhand Vidyalayi Shiksha Parishad" },
    { id: 55, name: "Uttranchal Shiksha Avam Pariksha Parishad" },
    { id: 56, name: "West Bengal Board of Madrasa Education" },
    { id: 57, name: "West Bengal Board of Secondary Education" },
    { id: 58, name: "The West Bengal Council of Rabindra Open Schooling " },
    { id: 59, name: "International Board" },
    { id: 60, name: "Board of Secondary Education Telangana" },
    { id: 61, name: "UP Board of Madrasa Education" },
    { id: 62, name: "Maharshi Patanjali Sanskrit Sansthan Bhopal" },
    { id: 63, name: "Visva Bharati University" },
    { id: 64, name: "Dayalbagh Education Institute" },
    { id: 65, name: "Gurukul Mahavidyalaya Jwalapur Haridwar" },
    { id: 66, name: "Uttar Pradesh Board of Secondary Sanskrit Education" },
    { id: 67, name: "Jharkhand Secondary Examination Board" },
    { id: 68, name: "Sampoornanand Sanskrit Vishwavidyalaya" },
    { id: 69, name: "Telangana Open School Society" },
    { id: 70, name: "Uttarakhand Sanskrit Shiksha Board" },
  ];
  const classListArray = [
    {
      value: "Primary (Class 1 - 6)",
      name: "Primary (Class 1 - 6)",
    },
    {
      value: "Secondary (Class 7 - 12)",
      name: "Secondary (Class 7 - 12)",
    },
    {
      value: "Graduation",
      name: "Graduation",
    },
    {
      value: "Post Graduation",
      name: "Post Graduation",
    },
  ];
  const [languagesArray, setLanguagesArray] = useState([
    { name: "English", id: 5 },
    { name: "Hindi", id: 7 },
    { name: "Assamese", id: 1 },
    { name: "Bengali", id: 2 },
    { name: "Bodo", id: 3 },
    { name: "Dogri", id: 4 },
    { name: "Gujarati", id: 6 },
    { name: "Kannada", id: 8 },
    { name: "Kashmiri", id: 9 },
    { name: "Konkani", id: 10 },
    { name: "Malayalam", id: 11 },
    { name: "Manipuri", id: 12 },
    { name: "Marathi", id: 13 },
    { name: "Maithili", id: 14 },
    { name: "Nepali", id: 15 },
    { name: "Oriya", id: 16 },
    { name: "Punjabi", id: 17 },
    { name: "Sanskrit", id: 18 },
    { name: "Santhali", id: 19 },
    { name: "Sindhi", id: 20 },
    { name: "Tamil", id: 21 },
    { name: "Telugu", id: 22 },
    { name: "Urdu", id: 23 },
  ]);
  const [subjectListArray, setSubjectListArray] = useState([
    { name: "Accountancy", id: 1 },
    { name: "Arabic", id: 2 },
    { name: "Assamese", id: 3 },
    { name: "Bengali", id: 4 },
    { name: "Bharatiya Darshan", id: 5 },
    { name: "Biology", id: 6 },
    { name: "Business Studies", id: 7 },
    { name: "Chemistry", id: 8 },
    { name: "Computer Science", id: 9 },
    { name: "Data Entry Operations", id: 10 },
    { name: "Economics", id: 11 },
    { name: "English", id: 12 },
    { name: "Environmental Science", id: 13 },
    { name: "Gujarati", id: 14 },
    { name: "Hindi", id: 15 },
    { name: "History", id: 16 },
    { name: "Home Science", id: 17 },
    { name: "Indian Culture & Heritage", id: 18 },
    { name: "Introduction to Law", id: 19 },
    { name: "Kannada", id: 20 },
    { name: "Library and Information Science", id: 21 },
    { name: "Malayalam", id: 22 },
    { name: "Marathi", id: 23 },
    { name: "Mass Communication", id: 24 },
    { name: "Mathematics", id: 25 },
    { name: "Nepali", id: 26 },
    { name: "Odia", id: 27 },
    { name: "Painting", id: 28 },
    { name: "Persian", id: 29 },
    { name: "Physics", id: 30 },
    { name: "Political Science", id: 31 },
    { name: "Psychology", id: 32 },
    { name: "Punjabi", id: 33 },
    { name: "Sanskrit", id: 34 },
    { name: "Sanskrit Sahitya", id: 35 },
    { name: "Sanskrit Vyakaran", id: 36 },
    { name: "Science and Technology", id: 37 },
    { name: "Social Science", id: 38 },
    { name: "Sociology", id: 39 },
    { name: "Tamil", id: 40 },
    { name: "Telugu", id: 41 },
    { name: "Tourism", id: 42 },
    { name: "Urdu", id: 43 },
    { name: "Veda Adhyan", id: 44 },
  ]);

  const SearchHandle = () => {
    if (!selectedSubject || selectedSubject == -1) {
      setSelectedSubjectError(true);
    }
    if (!selectedlanguage || selectedlanguage == -1) {
      setSelectedlanguageError(true);
    }
  /*  if (!boardValue || boardValue == -1) {
      setBoardValueError(true);
    }*/
    if (!classValue || classValue == -1) {
      setClassValueError(true);
    }
    if (
      !selectedSubject ||
      selectedSubject == -1 ||
      !selectedlanguage ||
      selectedlanguage == -1 ||
      //!boardValue ||
     // boardValue == -1 ||
      !classValue ||
      classValue == -1
    ) {
      return;
    }
    const tempData = {
      subjects: selectedSubject,
      languages: selectedlanguage,
      board: boardValue,
      classValue: classValue,
    };
    console.log("tempData", tempData);
    navigate("/TutorsList", {
      state: {
        data: tempData,
      },
    });
  };
  return (
    <div>
      {" "}
      <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle className="titleCenter">
          Find Tutors
          <span className="closeIconRight" style={{ float: "right" }}>
            <ClearIcon
              onClick={() => {
                open();
              }}
            />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="schedule_padding_filed">
            <label>Select Subjects</label>
            <select
              className="form-control"
              value={selectedSubject}
              onChange={(e) => {
                setSelectedSubject(e.target.value);
                setSelectedSubjectError(false);
              }}
            >
              <option value={-1}>Select Subject</option>
              {subjectListArray.map((row, index) => (
                <option key={index} value={row.name}>
                  {row.name}
                </option>
              ))}
            </select>
            {selectedSubjectError && (
              <span className="blank_error">Select Subject</span>
            )}
          </div>
          <div className="schedule_padding_filed">
            <label>Select Languages </label>
            <select
              className="form-control"
              value={selectedlanguage}
              onChange={(e) => {
                setSelectedlanguage(e.target.value);
                setSelectedlanguageError(false);
              }}
            >
              <option value={-1}>Select Languages</option>
              {languagesArray.map((row, index) => (
                <option value={row.name}>{row.name}</option>
              ))}
            </select>
            {selectedlanguageError && (
              <span className="blank_error">Select Languages</span>
            )}
          </div>
          <div className="schedule_padding_filed">
            <label>Select Class</label>
            <select
              className="form-control"
              value={classValue}
              onChange={(e) => {
                setClassValue(e.target.value);
                setClassValueError(false);
              }}
            >
              <option value={-1}>Select Class</option>
              {classListArray.map((row, index) => (
                <option key={index} value={row.value}>
                  {row.name}
                </option>
              ))}
            </select>
            {classValueError && (
              <span className="blank_error">Select Class</span>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="common_button "
            onClick={() => {
              SearchHandle();
            }}
          >
            Search
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FindTutorDailog;
