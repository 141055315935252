import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import GoogleMap from "./GoogleMap";
import TextField from "@mui/material/TextField";
import axios from "../../services/axios";
import Loader from "../Loader/Loader";
import { baseUrl } from "../../services";
import ReCAPTCHA from "react-google-recaptcha";
import { Card } from "@material-ui/core";
const config = require("../../config/default.json");

const ContactUs = () => {
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchErr, setRecaptchaErr] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [messageValue, setMessageValue] = useState("");

  const SheduleMeeting = async () => {
    try {
      if (fullName === "") {
        setFullNameError(true);
        return;
      }
      if (phoneNumber === "") {
        setPhoneNumberError(true);
        return;
      }
      const emailReg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailId.match(emailReg)) {
        setEmailIdError(true);
        return;
      }
      // if (!recaptchaValue) {
      //   setRecaptchaErr(true);
      //   return;
      // }
      setIsLoading(true);
      const { data } = await axios.post(baseUrl + "/enquirySchool", {
        email: emailId,
        phone: phoneNumber,
        date: new Date(),
        name: fullName,
        messageValue: messageValue,
      });
      if (data) {
        alert(data);
        setIsLoading(false);
      }
      console.log("data", data);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };
  return (
    <div>
      <div className="Contact_us_main_div">
        <h2 className="Faq_heading">Contact Us</h2>
        <div>
          <Grid className="common_main_grid">
            <Grid item md={4}></Grid>
            <Grid item md={4}>
              <div>
                <Card className="cardPaddingmargin">
                  <div className="schedule_padding_filed">
                    <TextField
                      id="standard-basic"
                      label="Full name"
                      variant="standard"
                      className="common_textfield_width"
                      value={fullName}
                      onChange={(e) => {
                        setFullName(e.target.value);
                        setFullNameError(false);
                      }}
                    />
                    {fullNameError && (
                      <span className="blank_error">Enter full name</span>
                    )}
                  </div>
                  <div className="schedule_padding_filed">
                    <TextField
                      id="standard-basic"
                      label="Phone number"
                      variant="standard"
                      className="common_textfield_width"
                      value={phoneNumber}
                      onChange={(e) => {
                        if (isNaN(e.target.value)) {
                          return;
                        }
                        setPhoneNumber(e.target.value);
                        setPhoneNumberError(false);
                      }}
                    />
                    {phoneNumberError && (
                      <span className="blank_error">Enter phone number</span>
                    )}
                  </div>
                  <div className="schedule_padding_filed">
                    <TextField
                      id="standard-basic"
                      label="Email Address"
                      variant="standard"
                      className="common_textfield_width"
                      value={emailId}
                      onChange={(e) => {
                        setEmailId(e.target.value);
                        setEmailIdError(false);
                      }}
                    />
                    {emailIdError && (
                      <span className="blank_error">Enter Email Id</span>
                    )}
                  </div>
                  <div className="schedule_padding_filed">
                    <textarea
                      rows="4"
                      placeholder="message here"
                      className="form-control"
                      value={messageValue}
                      onChange={(e) => {
                        setMessageValue(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <div className="mt-3 my_recaptcha">
                    <p>
                      <ReCAPTCHA
                        sitekey={config.app.siteKey}
                        //   onChange={callback}
                        onChange={(code) => {
                          if (recaptchErr) setRecaptchaErr(false);
                          setRecaptchaValue(code);
                        }}
                      />
                    </p>
                    {recaptchErr && (
                      <p className="text-danger">Please validate recaptcha</p>
                    )}
                  </div>
                  <div className="pt-2 text-right">
                    <button
                      className="common_button"
                      onClick={() => {
                        SheduleMeeting();
                      }}
                    >
                      Contact Me
                    </button>
                  </div>
                </Card>
              </div>
            </Grid>
            <Grid item md={4}></Grid>
          </Grid>
          <Grid className="common_main_grid">
            <Grid item md={1}></Grid>
            <Grid item md={9}>
              <div className="position_absolute">
                <div className="contactus_left_card" style={{ zIndex: 99 }}>
                  <div className="contact_address_data">
                    <p>No. 334, 4th Floor, 27th Main Road,</p>
                    <p>2nd Sector, HSR Layout</p>
                    <p>Bengaluru, Karnataka 560102</p>
                    <p>Email: contact@epalsoftware.com</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="shcedlue_meeting_padding_both padding_left">
                  {" "}
                  <GoogleMap />
                </div>
              </div>
            </Grid>
            <Grid item md={2}></Grid>
          </Grid>
        </div>
      </div>
      <Loader loading={isLoading} />
    </div>
  );
};

export default ContactUs;
