import React from "react";
import Grid from "@mui/material/Grid";
import "./WebsiteHomepage.css";
import Frame_39_1 from "../Images/Icon/Frame 39-1.svg";
import FeatureCard from "./FeatureCard";
import import_contacts from "../Images/Icon/import-contacts.svg";
import file_copy from "../Images/Icon/file-copy.svg";
import supervisor_account from "../Images/Icon/supervisor-account.svg";
import monetization_on from "../Images/Icon/monetization-on.svg";
import mobile_screen_share from "../Images/Icon/mobile-screen-share.svg";
import account_balance from "../Images/Icon/account-balance.svg";
import NoticeBoard from "../Images/Icon/NoticeBoard.webp";

const NewFeaturesData = () => {
  return (
    <div style={{ background: "#F2F2F2" }}>
      <div className="best_school_heading paddingTopNewFeature">
        <h2 className="common_h2">Features</h2>
      </div>
      <div className="featureCardPaddingBottom">
        <div className="featuresMainDivPadding">
          <Grid className="common_main_grid">
            <Grid item md={6}>
              <FeatureCard
                Image={Frame_39_1}
                Heading="Unlimited online classes"
                Para="Teach your students online for unlimited hours."
              />
            </Grid>
            <Grid item md={6}>
              <FeatureCard
                Image={file_copy}
                Heading="Online Assignments"
                Para="Prepare assignments for students and track their progress."
              />
            </Grid>
          </Grid>
        </div>
        <div className="featuresMainDivPadding">
          <Grid className="common_main_grid">
            <Grid item md={6}>
              <FeatureCard
                Image={supervisor_account}
                Heading="Student and teacher apps"
                Para="Specially designed websites and apps for students and teachers."
              />
            </Grid>
            <Grid item md={6}>
              <FeatureCard
                Image={monetization_on}
                Heading="Online payments"
                Para="Accept payments online from your students."
              />
            </Grid>
          </Grid>
        </div>
        <div className="featuresMainDivPadding">
          <Grid className="common_main_grid">
            <Grid item md={6}>
              <FeatureCard
                Image={mobile_screen_share}
                Heading="Communication"
                Para="Personalized SMS and email for easier connecting with your students."
              />
            </Grid>
            <Grid item md={6}>
              <FeatureCard
                Image={import_contacts}
                Heading="Online Study Materials"
                Para="Share and organize the study materials for your students."
              />
            </Grid>
          </Grid>
        </div>
        <div className="featuresMainDivPadding">
          <Grid className="common_main_grid">
            <Grid item md={6}>
              <FeatureCard
                Image={supervisor_account}
                Heading="Manage student profile"
                Para="Manage your students profile and have complete control."
              />
            </Grid>
            <Grid item md={6}>
              <FeatureCard
                Image={monetization_on}
                Heading="Time table and attendance"
                Para="Track your time for each class and keep a tab on student attendance."
              />
            </Grid>
          </Grid>
        </div>
        <div className="featuresMainDivPadding">
          <Grid className="common_main_grid">
            <Grid item md={6}>
              <FeatureCard
                Image={account_balance}
                Heading="Manage Multi Branches"
                Para="Supports multiple branches with a single login."
              />
            </Grid>
            <Grid item md={6}>
              <FeatureCard
                Image={import_contacts}
                Heading="Examinations and results tracking"
                Para="Unlimited exam setups, track results and schedule dates and accommodate changes in the dates with absolute ease."
              />
            </Grid>
          </Grid>
        </div>
        <div className="featuresMainDivPadding">
          <Grid className="common_main_grid">
            <Grid item md={6}>
              <FeatureCard
                Image={NoticeBoard}
                Heading="Notice Board"
                Para="Digital noticeboard so that the students stay up to date with the latest updates."
              />
            </Grid>
            <Grid item md={6}>
              <FeatureCard
                Image={monetization_on}
                Heading="Admin portal"
                Para="Admin login to manage operational activities like onboarding students, fees, timetable and many more.. "
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default NewFeaturesData;
