import CachedIcon from "@mui/icons-material/Cached";
import ForumIcon from "@mui/icons-material/Forum";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Typography from "@mui/material/Typography";
import SettingsInputCompositeIcon from "@mui/icons-material/SettingsInputComposite";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import account_balance from "../Images/Icon/account-balance.svg";
import build_icon from "../Images/Icon/build.svg";
import call_split from "../Images/Icon/call-split.svg";
import device_hub from "../Images/Icon/device-hub.svg";
import done_outline from "../Images/Icon/done-outline.svg";
import file_copy from "../Images/Icon/file-copy.svg";
import forum_Kopie from "../Images/Icon/forum Kopie.svg";
import Frame_39_1 from "../Images/Icon/Frame 39-1.svg";
import Frame_39_2 from "../Images/Icon/Frame 39-2.svg";
import Frame_39 from "../Images/Icon/Frame 39.svg";
import how_to_reg from "../Images/Icon/how-to-reg.svg";
import import_contacts from "../Images/Icon/import-contacts.svg";
import important_devices from "../Images/Icon/important-devices.svg";
import launch_icon from "../Images/Icon/launch.svg";
import mobile_screen_share from "../Images/Icon/mobile-screen-share.svg";
import monetization_on from "../Images/Icon/monetization-on.svg";
import pageview_icon from "../Images/Icon/pageview.svg";
import priority_high from "../Images/Icon/priority-high.svg";
import record_voice_over from "../Images/Icon/record-voice-over.svg";
import settings_input_hdmi from "../Images/Icon/settings-input-hdmi.svg";
import Square_foot from "../Images/Icon/square-foot.svg";
import supervisor_account from "../Images/Icon/supervisor-account.svg";
import surround_sound from "../Images/Icon/surround-sound.svg";
import timeline_icon from "../Images/Icon/timeline.svg";
import FeatureCard from "./FeatureCard";
const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function CustomizedTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div>
        <div className="best_school_heading">
          <h2 className="common_h2">Features</h2>
        </div>
        <div className="features_main_div_padding">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ bgcolor: "#fff" }}>
              <AntTabs
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab
                  // label="Tab 1"
                  icon={<CachedIcon />}
                  iconPosition="end"
                  label="Analysing and Automating"
                  style={{ minWidth: 130 }}
                  {...a11yProps(0)}
                />
                <AntTab
                  icon={<SettingsInputCompositeIcon />}
                  iconPosition="end"
                  label="Complete control"
                  {...a11yProps(0)}
                  style={{ minWidth: 130 }}
                />
                <AntTab
                  icon={<MenuBookIcon />}
                  iconPosition="end"
                  label="Flawless education"
                  {...a11yProps(0)}
                  style={{ minWidth: 130 }}
                />
                <AntTab
                  icon={<ForumIcon />}
                  iconPosition="end"
                  label="Communication and feedback"
                  {...a11yProps(0)}
                  style={{ minWidth: 130 }}
                />
              </AntTabs>
              <Box sx={{ p: 3 }} />
              <TabPanel value={value} index={0}>
                <div className="feature_anylises_background">
                  <Grid className="common_main_grid">
                    <Grid item md={3}>
                      <FeatureCard
                        Image={Frame_39}
                        heading="A cloud-based virtual platform"
                        para={
                          "Streamlines the school management ensuring better education delivery and practising more control over school operational activities "
                        }
                      />
                    </Grid>

                    <Grid item md={3}>
                      <FeatureCard
                        Image={Square_foot}
                        heading="Seamless integration across different branches"
                        para={
                          " One software can be used across all your school branches, domestic and international."
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FeatureCard
                        Image={Frame_39_1}
                        heading="Virtual classrooms"
                        para={
                          "Supporting interactive displays and engaging ways of imparting education to the new-age students. Maging other virtual educational platforms that teachers use regularly as a part of their curriculum."
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FeatureCard
                        Image={Frame_39_2}
                        heading="Easy integration on every platform"
                        para={
                          "Does not need regular monitoring, can be accessed easily through desktop and mobile and works seamlessly on both Android and iOS devices."
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="feature_anylises_background">
                  <Grid className="common_main_grid">
                    <Grid item md={3}>
                      <FeatureCard
                        Image={done_outline}
                        heading="No hassles while using"
                        para={
                          "An easy-to-use, flexible and comprehensive software that streamlines school administrative processes."
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FeatureCard
                        Image={build_icon}
                        heading="Customisable interface"
                        para={
                          "Supports all kinds of establishments, ranging from large institutions, small schools, kindergarten and preschools and can be customised to suit unique requirements."
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FeatureCard
                        Image={launch_icon}
                        heading="Different portals for students"
                        para={
                          " A specially designed student portal that can be accessed easily comes packed with features like online lesson plans and on-demand lectures."
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FeatureCard
                        Image={settings_input_hdmi}
                        heading="Creating a network"
                        para={
                          "Keeping the parents in the loop of their child’s progress, new changes in school policies and other relevant information through the parents portal."
                        }
                      />
                    </Grid>
                  </Grid>
                  {/* ------second row-------- */}
                  <div>
                    <Grid className="common_main_grid">
                      <Grid item md={3}>
                        <FeatureCard
                          Image={priority_high}
                          heading="Updating information"
                          para={
                            "Helping you update information and onboard existing students on the cloud, in addition to the new admissions, therefore there is no loss of data"
                          }
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FeatureCard
                          Image={supervisor_account}
                          heading="Different portals for teachers"
                          para={
                            "Comes with a teacher portal where they can access student data, their individual progress and alterations in the school policies and administration."
                          }
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FeatureCard
                          Image={monetization_on}
                          heading="Hassle-free fee deposits"
                          para={
                            "Automating the process of depositing the fee so the parents get an option of depositing money from anywhere and at any time and the school gets an option of tracking the fee status in a more streamlined manner."
                          }
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FeatureCard
                          Image={timeline_icon}
                          heading="Real-time tracking"
                          para={
                            "Keeping a tab on the vehicles associated with picking and dropping students and faculty, as well as managing the complete activities of the transport management staff.  Real-time tracking of school vehicles will assist in parents picking up their wards from designated places without waiting for long as well as get reports of any unwanted incidents."
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                  {/* ------third row--------- */}
                  <div>
                    <Grid className="common_main_grid">
                      <Grid item md={3}>
                        <FeatureCard
                          Image={surround_sound}
                          heading="Events and special programs"
                          para={
                            "Streamlining the process of event registration for parents, students and guests."
                          }
                        />
                      </Grid>
                      <Grid item md={3}></Grid>
                      <Grid item md={3}></Grid>
                      <Grid item md={3}></Grid>
                    </Grid>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="feature_anylises_background">
                  <Grid className="common_main_grid">
                    <Grid item md={3}>
                      <FeatureCard
                        Image={pageview_icon}
                        heading="Tracking assignments"
                        para={
                          "Tracking the submitted assignments and keeping a record of the grade obtained to minimise human error."
                        }
                      />
                    </Grid>

                    <Grid item md={3}>
                      <FeatureCard
                        Image={how_to_reg}
                        heading="Keeping a tab on attendance"
                        para={
                          "Automating the attendance procedure to ensure correct attendance of the students and extract attendance details at any time at a later stage."
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FeatureCard
                        Image={account_balance}
                        heading="Well-defined admission process"
                        para={
                          "Digitising the admission process to make it more student-friendly and maintaining a database of students onboard. Taking into consideration the accepted/rejected/waiting status and updating respective portals with the same."
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FeatureCard
                        Image={important_devices}
                        heading="Easy to manage faculty data"
                        para={
                          "Maintaining a  database  of all the faculty members. It also assists in tracking the performance of faculty members, their schedules and their onboarding process."
                        }
                      />
                    </Grid>
                  </Grid>
                  {/* ----second row---- */}
                  <div>
                    <Grid className="common_main_grid">
                      <Grid item md={3}>
                        <FeatureCard
                          Image={call_split}
                          heading="Leave management"
                          para={
                            "Streamlining the process of applying for and granting leave to faculty members and other members of the school administration to avoid last-minute hassles."
                          }
                        />
                      </Grid>

                      <Grid item md={3}>
                        <FeatureCard
                          Image={file_copy}
                          heading="Learning and assessment"
                          para={
                            "Unlimited exam setups, track results and schedule dates and accommodate changes in the dates with absolute ease."
                          }
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FeatureCard
                          Image={import_contacts}
                          heading="Library management"
                          para={
                            "Virtual library management focuses on tracking the issue and return of the library books, adding new books to the collection and discarding books no longer in use."
                          }
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FeatureCard
                          Image={forum_Kopie}
                          heading="Flawless communication"
                          para={
                            "Digital noticeboard so that the students stay up to date with the latest updates on school administration, changes in policies, curriculum, exams, the status of results and many more."
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div className="feature_anylises_background">
                  <Grid className="common_main_grid">
                    <Grid item md={3}>
                      <FeatureCard
                        Image={mobile_screen_share}
                        heading="Systematic communication"
                        para={
                          "Personalised SMS and email support to make communication between students, teachers and parents seamless and convenient. Also, minimising human intervention to avoid any loss of information."
                        }
                      />
                    </Grid>

                    <Grid item md={3}>
                      <FeatureCard
                        Image={record_voice_over}
                        heading="Feedback and results"
                        para={
                          " Providing virtual, one-on-one feedback and results to inculcate a positive and affirmative exchange of ideas and motivating students the right way."
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <FeatureCard
                        Image={device_hub}
                        heading="Transparency and availability"
                        para={
                          "Having a ‘Contact Us’ section for parents and students to reach out to the school administration at their convenience."
                        }
                      />
                    </Grid>
                    <Grid item md={3}></Grid>
                  </Grid>
                </div>
              </TabPanel>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
}
