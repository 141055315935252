import React from "react";
import HOC from "../HOC/HOC";
import "./Blogs.css";
import { useEffect } from "react";
import { BlogsArrayData } from "./BlogsData";
import { useState } from "react";
import { Helmet } from "react-helmet";

const BlogsDetails = () => {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    BlogsArrayData.map((item) => {
      if (item.location === decodeURI(window.location.pathname)) {
        setBlogData(item);
      }
    });
  }, []);

  return (
    <div>
      <Helmet>
        <title>{`${blogData?.metaTitle}`} </title>
        <meta name="title" content={blogData.metaTitle} />
        <meta name="keywords" content={blogData.metaKeyWord} />
        <meta name="description" content={blogData.metaDescription} />
      </Helmet>
      <div className="blogsmainDiv">
        <div className="blogDetail Padding_both">
          <div className="detailHeadingCenter">
            <h1>{blogData.heading}</h1>
            <div className="blogDateFlex">
              <div>
                <img
                  src="https://pepperyourcontent.com/wp-content/uploads/2022/05/Pepper-Logo.jpg"
                  width={20}
                  alt="No_image"
                />
                <b>{blogData.authorName}</b>
              </div>
              <div className="blogDetailsPadding">
                • Posted on {blogData.date}
              </div>
              <div className="blogDetailsPadding">• 4 min read</div>
            </div>
          </div>
          {/* image section--------- */}
          <div className="blogImagepadiing">
            <img src={blogData.image} width="100%" alt="No_image" />
            <div className="contentColorandCenter">
              <p>{blogData.summary}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HOC(BlogsDetails);
