import React, { useState } from "react";
import "./HOC.css";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Logo from "../Images/Logo.png";
import SignUpDailog from "../SignUp/SignUpDailog";
import ConfirmNumber from "../SignUp/ConfirmNumber";
import FinishSignUp from "../SignUp/FinishSignUp";
import MessagePopUp from "../SignUp/MessagePopUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate } from "react-router-dom";

const HomeHeader = (props) => {
  const navigate = useNavigate();
  const [dailogOpen, setDailogOpen] = useState(false);
  const [confirmDailogOpen, setConfirmDailogOpen] = useState(false);
  const [openFinishDailog, setopenFinishDailog] = useState(false);
  const [responseId, setResponseId] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [dialogOpen, setdialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [Sidebar, setSidebar] = useState(false);
  const [curencyCodeValue, setCurencyCodeValue] = useState("");
  const [countrySymbal, setCountrySymbal] = useState("");

  // sign up dailog open first
  const signUpHandle = () => {
    setDailogOpen(!dailogOpen);
  };

  // confirm dailog open
  const continueHandle = () => {
    setConfirmDailogOpen(!confirmDailogOpen);
    setDailogOpen(false);
  };
  const otpId = (id, phoneNo, curencyCode, countryValue) => {
    setResponseId(id);
    setphoneNumber(phoneNo);
    setCurencyCodeValue(curencyCode);
    setCountrySymbal(countryValue);
  };

  // confirm button handle
  const VerifiyHandle = () => {
    setopenFinishDailog(!openFinishDailog);
    setConfirmDailogOpen(false);
    setDailogOpen(false);
  };
  // finish handle
  const finishHandle = () => {
    setopenFinishDailog(false);
    setdialogOpen(true);
  };
  // ok button handle
  const messagePopUpClose = () => {
    setdialogOpen(false);
    window.open("https://liveweb.skoolfirst.com/");
  };

  const loginHandle = () => {
    window.open("https://liveweb.skoolfirst.com/");
  };

  // error otp mesage------
  const ErrorMessageHandle = () => {
    setErrorMessage(!errorMessage);
    setConfirmDailogOpen(false);
  };
  // ok button handle
  const errorMessageOkHandle = () => {
    window.location = "/";
  };

  const menuBarHandle = () => {
    try {
      setSidebar(!Sidebar);
      if (Sidebar) {
        document.getElementById("mySidenav").style.width = "250px";
      } else {
        document.getElementById("mySidenav").style.width = "0px";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const blogsHandle = () => {
    window.location = "/Blog";
  };
  return (
    <div>
      <div>
        <AppBar position="fixed" className="home_header_background">
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              <img src={Logo} alt="No-logo" className="header_logo_color" />
            </Typography>
            <div className="header_home_flex_content" />
            <div className="header_flex_grow">
              <p>
                <a href="/#home"> Home</a>
              </p>
              <p>
                <a href="/#features"> Features</a>
              </p>
              {/* <p>
                <a href="/#about_us">About Us</a>
              </p> */}
              <p>
                <a href="/#faq">FAQs</a>
              </p>
              <p>
                <a href="/#pricing">Pricing</a>
              </p>
              <p
                className="cursor"
                onClick={() => {
                  blogsHandle();
                }}
              >
                Blogs
              </p>
              <p>
                <a href="/#contact_us">Contact Us</a>
              </p>
              <button
                className="common_button buttonTopSetting"
                onClick={() => {
                  loginHandle();
                }}
              >
                Login
              </button>
              <button
                className="common_button buttonTopSetting"
                onClick={() => {
                  // signUpHandle();
                  navigate("/CreateTeacherAccount");
                }}
              >
                Sign Up
              </button>
            </div>
            {/* -------------mobile view section--------------- */}
            <div className="mobileMenudivShow">
              <div
                className="menuBarColor"
                onClick={() => {
                  menuBarHandle();
                }}
              >
                <i className="fa fa-bars"></i>
              </div>
              <div id="mySidenav" className="sidenav">
                <div className="cross_icon_style">
                  <i
                    className="fa fa-times cursor"
                    onClick={() => {
                      document.getElementById("mySidenav").style.width = "0px";
                    }}
                  ></i>
                </div>
                <a href="/#home" className="logout_Pointer_cursor ">
                  Home
                </a>
                <a href="/#features"> Features</a>
                <a href="/#faq">FAQs</a>
                <a href="/#pricing">Pricing</a>
                <p
                  onClick={() => {
                    blogsHandle();
                  }}
                >
                  Blogs
                </p>
                <a href="/#contact_us">Contact Us</a>
                <div className="buttonsetInSidebar">
                  <button
                    className="common_button "
                    onClick={() => {
                      loginHandle();
                    }}
                  >
                    Login
                  </button>
                </div>
                <div className="buttonsetInSidebar">
                  <button
                    className="common_button "
                    onClick={() => {
                      signUpHandle();
                    }}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
            {/* -------------mobile view section end--------------- */}
          </Toolbar>
        </AppBar>
      </div>
      {/* -------first dailog---------- */}
      {dailogOpen && (
        <SignUpDailog
          open={signUpHandle}
          continueHandle={continueHandle}
          otpId={otpId}
          VerifiyHandle={VerifiyHandle}
        />
      )}
      {/* -------second dailog--------- */}
      {confirmDailogOpen && (
        <ConfirmNumber
          open={continueHandle}
          VerifiyHandle={VerifiyHandle}
          responseId={responseId}
          phoneNumber={phoneNumber}
          ErrorMessageHandle={ErrorMessageHandle}
        />
      )}
      {/* -------third dailog---------- */}
      {openFinishDailog && (
        <FinishSignUp
          open={VerifiyHandle}
          finishHandle={finishHandle}
          phoneNumber={phoneNumber}
          curencyCodeValue={curencyCodeValue}
          countrySymbal={countrySymbal}
        />
      )}
      {/* ---------pop message-=-=-=-=-=-=-= */}
      {dialogOpen && (
        <MessagePopUp
          dialogOpen={dialogOpen}
          messagePopUpClose={messagePopUpClose}
        >
          <div className="contentCenter">
            <div>
              <CheckCircleIcon className="iconFontSize" />
            </div>
            <div>
              <p>Congratulations !!</p>
              <p>Please check your Email for Login ID and Password.</p>
            </div>
            <div>
              <button
                className="buttonColorWidth"
                onClick={() => {
                  messagePopUpClose();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </MessagePopUp>
      )}
      {/* ---------otp message error */}
      {errorMessage && (
        <MessagePopUp
          dialogOpen={ErrorMessageHandle}
          messagePopUpClose={errorMessageOkHandle}
        >
          <div className="contentCenter">
            <div>
              <HighlightOffIcon className="iconFontSizeClose" />
            </div>
            <div>
              <p>
                You have exceeded max number of tries. Please contact us at
                <a href="contact@epalsoftware.com" target="_blank">
                  {" "}
                  contact@epalsoftware.com
                </a>
                .
              </p>
            </div>
            <div>
              <button
                className="buttonColorWidthError"
                onClick={() => {
                  errorMessageOkHandle();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </MessagePopUp>
      )}
    </div>
  );
};

export default HomeHeader;
