import React, { useState } from "react";
import HOC from "../HOC/HOC";
import { Card } from "@material-ui/core";
import "./SignUp.css";
import Loader from "../Loader/Loader";
import ClearIcon from "@mui/icons-material/Clear";
import { verifyOTPSend } from "../../services";
import { useMutation } from "@apollo/react-hooks";
import MessagePopUp from "./MessagePopUp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const StudentOtpVerifyPage = (props) => {
  const {
    phoneNumber,
    responseId,
    curencyCodeValue,
    countrySymbal,
    phoneWithOutDialCode,
    row,
  } = props.location.state.data;
  const [firstDigit, setFirstDigit] = useState("");
  const [secondDigit, setSecondDigit] = useState("");
  const [thirdDigit, setThirdDigit] = useState("");
  const [fourthDigit, setFourthDigit] = useState("");
  const [fifthDigit, setFifthDigit] = useState("");
  const [sixDigit, setSixDigit] = useState("");
  const [digitError, setdigitError] = useState(false);
  const [verify_OTP_Send, {}] = useMutation(verifyOTPSend);
  const [isLoaing, setIsLoaing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageValue, setErrorMessageValue] = useState(false);

  const verifiyHandleValidation = async () => {
    try {
      if (
        !firstDigit ||
        !secondDigit ||
        !thirdDigit ||
        !fourthDigit ||
        !fifthDigit ||
        !sixDigit
      ) {
        setdigitError(true);
        return;
      }
      setIsLoaing(true);
      let OTPValue =
        firstDigit +
        secondDigit +
        thirdDigit +
        fourthDigit +
        fifthDigit +
        sixDigit;
      const { data } = await verify_OTP_Send({
        variables: {
          otp: OTPValue,
          id: responseId,
        },
      });
      if (data) {
        if (data.verifyOTP.errorCode === 0 && data.verifyOTP.id === 0) {
          setErrorMessage(data.verifyOTP.message);
          setIsLoaing(false);
          return;
        } else if (data.verifyOTP.errorCode === 1) {
          ErrorMessageHandle();
        } else {
          verifiyHandle();
        }

        setIsLoaing(false);
      }
      verifiyHandle();
    } catch (error) {
      setIsLoaing(false);
    }
  };
  const verifiyHandle = () => {
    try {
      let tempdata = {
        phoneNumber: phoneNumber,
        curencyCodeValue: curencyCodeValue,
        countrySymbal: countrySymbal,
        phoneWithOutDialCode: phoneWithOutDialCode,
        // dayTime: dayTime,
        row: row,
      };
      props.navigate("/StudentSelectTeacher", {
        state: {
          data: tempdata,
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  const ErrorMessageHandle = () => {
    setErrorMessageValue(!errorMessageValue);
  };
  // ok button handle
  const errorMessageOkHandle = () => {
    setErrorMessageValue(false);
  };
  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };
  return (
    <>
      {/* ---------otp message error */}
      {errorMessageValue && (
        <MessagePopUp
          dialogOpen={ErrorMessageHandle}
          messagePopUpClose={errorMessageOkHandle}
        >
          <div className="contentCenter">
            <div>
              <HighlightOffIcon className="iconFontSizeClose" />
            </div>
            <div>
              <p>
                You have exceeded max number of tries. Please contact us at
                <a href="contact@epalsoftware.com" target="_blank">
                  {" "}
                  contact@epalsoftware.com
                </a>
                .
              </p>
            </div>
            <div>
              <button
                className="buttonColorWidthError"
                onClick={() => {
                  errorMessageOkHandle();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </MessagePopUp>
      )}
      <div className="website_home_main_div">
        <div className="paddingAccountTeacher">
          <Card>
            <div className="cardBgColor">
              <p className="titleCenterColor">Confirm your number</p>
              <div>
                <p className="paraFontset">
                  Enter the code we have sent via SMS to {phoneNumber}
                </p>
                <form>
                  <div className="otpTextFlex">
                    <input
                      type="text"
                      className="otpInputWidth"
                      name="txt1"
                      maxLength={1}
                      value={firstDigit}
                      autoComplete="off"
                      tabIndex="1"
                      onChange={(e) => {
                        setFirstDigit(e.target.value);
                        setdigitError(false);
                      }}
                      onKeyUp={(event) => {
                        inputfocus(event);
                      }}
                    />
                    <input
                      type="text"
                      className="otpInputWidth"
                      name="txt2"
                      maxLength={1}
                      tabIndex="2"
                      autoComplete="off"
                      value={secondDigit}
                      onChange={(e) => {
                        setSecondDigit(e.target.value);
                        setdigitError(false);
                      }}
                      onKeyUp={(event) => {
                        inputfocus(event);
                      }}
                    />
                    <input
                      type="text"
                      className="otpInputWidth"
                      name="txt3"
                      maxLength={1}
                      tabIndex="3"
                      autoComplete="off"
                      value={thirdDigit}
                      onChange={(e) => {
                        setThirdDigit(e.target.value);
                        setdigitError(false);
                      }}
                      onKeyUp={(event) => {
                        inputfocus(event);
                      }}
                    />
                    <input
                      type="text"
                      className="otpInputWidth"
                      name="txt4"
                      maxLength={1}
                      tabIndex="4"
                      autoComplete="off"
                      value={fourthDigit}
                      onChange={(e) => {
                        setFourthDigit(e.target.value);
                        setdigitError(false);
                      }}
                      onKeyUp={(event) => {
                        inputfocus(event);
                      }}
                    />
                    <input
                      type="text"
                      className="otpInputWidth"
                      name="txt5"
                      maxLength={1}
                      tabIndex="5"
                      autoComplete="off"
                      value={fifthDigit}
                      onChange={(e) => {
                        setFifthDigit(e.target.value);
                        setdigitError(false);
                      }}
                      onKeyUp={(event) => {
                        inputfocus(event);
                      }}
                    />
                    <input
                      type="text"
                      className="otpInputWidth"
                      name="txt6"
                      maxLength={1}
                      tabIndex="6"
                      autoComplete="off"
                      value={sixDigit}
                      onChange={(e) => {
                        setSixDigit(e.target.value);
                        setdigitError(false);
                      }}
                      onKeyUp={(event) => {
                        inputfocus(event);
                      }}
                    />
                  </div>
                </form>

                {digitError && <span className="blank_error">Enter OTP</span>}
                {errorMessage && (
                  <p className="blank_error p-2">{errorMessage}</p>
                )}
                <p className="paraFontset optionPadding">
                  Haven't recevied a code? contact us at{" "}
                  <a
                    href="https://teach.skoolfirst.com/#contact_us"
                    target="_blank"
                  >
                    contact@epalsoftware.com
                  </a>
                </p>
              </div>
              <button
                className="common_button"
                onClick={() => {
                  verifiyHandleValidation();
                }}
              >
                Verify
              </button>
            </div>
          </Card>
        </div>
        <Loader loading={isLoaing} />
      </div>
    </>
  );
};

export default HOC(StudentOtpVerifyPage);
