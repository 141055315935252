import React, { useState } from "react";
import "./SignUp.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { CountryDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ClearIcon from "@mui/icons-material/Clear";
import { LoginUser } from "../../services";
import { useMutation } from "@apollo/react-hooks";
import Loader from "../Loader/Loader";
import countryToCurrency from "country-to-currency";

const SignUpDailog = ({ open, continueHandle, otpId, VerifiyHandle }) => {
  const [countryValue, setCountryValue] = useState("IN");
  const [shortCode, setshortCode] = useState("in");
  const [phoneValue, setPhoneValue] = useState("");
  const [phoneValueError, setPhoneValueError] = useState(false);
  const [isLoaing, setIsLoaing] = useState(false);
  const [curencyCode, setcurencyCode] = useState("INR");
  const [warningMessage, setWarningMessage] = useState("");
  const [formatCount, setFormatCount] = useState(0);

  const [Login_User, {}] = useMutation(LoginUser);

  const selectCountry = (val) => {
    setcurencyCode(countryToCurrency[val]);
    setCountryValue(val);
    setshortCode(val.toLowerCase());
  };

  const handleValidation = async () => {
    try {
      if (!phoneValue) {
        setPhoneValueError(true);
        return;
      }
      if (phoneValue.length < formatCount) {
        setPhoneValueError(true);
        return;
      }
      setIsLoaing(true);
      const { data } = await Login_User({
        variables: {
          phoneNumber: phoneValue,
        },
      });
      if (data) {
        if (data?.startVerification?.errorCode === 1) {
          setWarningMessage(data?.startVerification?.message);
          setIsLoaing(false);
          return;
        }
        otpId(data.startVerification.id, phoneValue, curencyCode, countryValue);
        if (shortCode === "in") {
          continueHandle();
        } else {
          VerifiyHandle();
        }

        setIsLoaing(false);
      } else {
        alert("Something went wrong, contact us via email");
      }
    } catch (error) {
      console.log("startVerification error", error);
      alert("Something went wrong, contact us via email");
      setIsLoaing(false);
    }
  };

  const privacyPolicyHandle = () => {
    window.open("/privacy_policy");
  };
  const TermsAndUseHandle = () => {
    window.open("/TermsAndCondition");
  };
  const EulaHandle = () => {
    window.open("/endUserAggreement");
  };
  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle className="titleCenter">
          <span
            className="iconsLeftSet"
            onClick={() => {
              open();
            }}
          >
            <ClearIcon />
          </span>
          Create Teacher Account
        </DialogTitle>
        <DialogContent>
          <div>
            <div>
              <CountryDropdown
                className="countryDropDownborder"
                value={countryValue}
                valueType="short"
                onChange={(val) => {
                  selectCountry(val);
                  setPhoneValue("");
                  setFormatCount(0);
                }}
              />
            </div>
            <div className="numberInputPadding">
              <PhoneInput
                className="phoneInputheight"
                country={shortCode}
                countryCodeEditable={false}
                value={phoneValue}
                disableDropdown={true}
                onChange={(phone, formattedValue) => {
                  setFormatCount(
                    formattedValue.format.length -
                      formattedValue.format.replaceAll(".", "").length
                  );
                  setPhoneValueError(false);
                  setPhoneValue(phone);
                }}
              />
              {phoneValueError && (
                <span className="blank_error">Enter valid phone number</span>
              )}
              {warningMessage && (
                <span className="blank_error">{warningMessage}</span>
              )}
            </div>
            <p className="paraFontset textCenter">
              By continuing, you agree to our Legal{" "}
              <span className="PrivacyPolicyColor" onClick={TermsAndUseHandle}>
                (Terms of Use)
              </span>
              , End User License Agreement{" "}
              <span className="PrivacyPolicyColor" onClick={EulaHandle}>
                (EULA)
              </span>{" "}
              &{" "}
              <span
                className="PrivacyPolicyColor"
                onClick={privacyPolicyHandle}
              >
                Privacy Statement.{" "}
              </span>
              {/* We'll call or text you to confirm your number. Standard message
              and data rates apply.{" "} */}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="common_button "
            onClick={() => {
              handleValidation();
            }}
          >
            Continue
          </button>
        </DialogActions>
      </Dialog>
      <Loader loading={isLoaing} />
    </div>
  );
};

export default SignUpDailog;
