import React, { useState } from "react";
import HOC from "../HOC/HOC";
import { CountryDropdown } from "react-country-region-selector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { LoginUser } from "../../services";
import { useMutation } from "@apollo/react-hooks";
import Loader from "../Loader/Loader";
import countryToCurrency from "country-to-currency";
import { Card } from "@material-ui/core";
import "./SignUp.css";

const CreateStudentAccount = (props) => {
  const { row } = props.location.state.data;
  const [countryValue, setCountryValue] = useState("IN");
  const [shortCode, setshortCode] = useState("in");
  const [phoneValue, setPhoneValue] = useState("");
  const [phoneValueError, setPhoneValueError] = useState(false);
  const [isLoaing, setIsLoaing] = useState(false);
  const [curencyCode, setcurencyCode] = useState("INR");
  const [warningMessage, setWarningMessage] = useState("");
  const [formatCount, setFormatCount] = useState(0);
  const [phoneWithOutDialCode, setPhoneWithOutDialCode] = useState("");

  const [Login_User, {}] = useMutation(LoginUser);

  const selectCountry = (val) => {
    setcurencyCode(countryToCurrency[val]);
    setCountryValue(val);
    setshortCode(val.toLowerCase());
  };

  const handleValidation = async () => {
    try {
      if (!phoneValue) {
        setPhoneValueError(true);
        return;
      }
      if (phoneValue.length < formatCount) {
        setPhoneValueError(true);
        return;
      }
      setIsLoaing(true);
      const { data } = await Login_User({
        variables: {
          phoneNumber: phoneValue,
        },
      });
      if (data) {
        if (data?.startVerification?.errorCode === 1) {
          setWarningMessage(data?.startVerification?.message);
          setIsLoaing(false);
          return;
        }

        if (shortCode === "in") {
          let tempData = {
            responseId: data.startVerification.id,
            phoneNumber: phoneValue,
            curencyCodeValue: curencyCode,
            countrySymbal: countryValue,
            phoneWithOutDialCode: phoneWithOutDialCode,
            // dayTime: dayTime,
            row: row,
          };
          props.navigate("/StudentOtpVerifyPage", {
            state: {
              data: tempData,
            },
          });
        } else {
          let tempData = {
            phoneNumber: phoneValue,
            curencyCodeValue: curencyCode,
            countrySymbal: countryValue,
            phoneWithOutDialCode: phoneWithOutDialCode,
            // dayTime: dayTime,
            row: row,
          };
          props.navigate("/StudentSelectTeacher", {
            state: {
              data: tempData,
            },
          });
        }

        setIsLoaing(false);
      } else {
        alert("Something went wrong, contact us via email");
      }
    } catch (error) {
      console.log("startVerification error", error);
      alert("Something went wrong, contact us via email");
      setIsLoaing(false);
    }
  };

  const privacyPolicyHandle = () => {
    window.open("/privacy_policy");
  };
  const TermsAndUseHandle = () => {
    window.open("/TermsAndCondition");
  };
  const EulaHandle = () => {
    window.open("/endUserAggreement");
  };
  return (
    <div className="website_home_main_div">
      <div className="paddingAccountTeacher">
        <Card>
          <div className="cardBgColor">
            <p className="titleCenterColor">Create Student Account</p>
            <div>
              <CountryDropdown
                className="countryDropDownborder"
                value={countryValue}
                valueType="short"
                onChange={(val) => {
                  selectCountry(val);
                  setPhoneValue("");
                  setFormatCount(0);
                }}
              />
            </div>
            <div className="numberInputPadding">
              <PhoneInput
                className="phoneInputheight"
                country={shortCode}
                countryCodeEditable={false}
                value={phoneValue}
                disableDropdown={true}
                onChange={(phone, formattedValue) => {
                  setFormatCount(
                    formattedValue.format.length -
                      formattedValue.format.replaceAll(".", "").length
                  );
                  setPhoneWithOutDialCode(
                    phone.slice(formattedValue.dialCode.length)
                  );
                  setPhoneValueError(false);
                  setPhoneValue(phone);
                }}
              />
              {phoneValueError && (
                <span className="blank_error">Enter valid phone number</span>
              )}
              {warningMessage && (
                <span className="blank_error">{warningMessage}</span>
              )}
            </div>
            <p className="paraFontset textCenter">
              By continuing, you agree to our Legal{" "}
              <span className="PrivacyPolicyColor" onClick={TermsAndUseHandle}>
                (Terms of Use)
              </span>
              , End User License Agreement{" "}
              <span className="PrivacyPolicyColor" onClick={EulaHandle}>
                (EULA)
              </span>{" "}
              &{" "}
              <span
                className="PrivacyPolicyColor"
                onClick={privacyPolicyHandle}
              >
                Privacy Statement.{" "}
              </span>
              {/* We'll call or text you to confirm your number. Standard message
              and data rates apply.{" "} */}
            </p>
            <button
              className="common_button btnWidth"
              onClick={() => {
                handleValidation();
              }}
            >
              Continue
            </button>
          </div>
        </Card>
      </div>
      <Loader loading={isLoaing} />
    </div>
  );
};

export default HOC(CreateStudentAccount);
