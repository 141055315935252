import React, { useState } from "react";
import HOC from "../HOC/HOC";
import EastIcon from "@mui/icons-material/East";
import SouthIcon from "@mui/icons-material/South";
import FindTutorDailog from "../WebsiteHomepage/FindTutorDailog";

const PtStudentHowItWork = () => {
  const [tutorsDailogOpen, setTutorsDailogOpen] = useState(false);

  const findTutorsHandle = () => {
    setTutorsDailogOpen(!tutorsDailogOpen);
  };
  return (
    <>
      <div className="howItWorkMainDiv">
        <div className="howItWorkHeading">
          <h2>How it works for students</h2>
        </div>
        <div className="howitpaddingBothForStudent">
          {/* card section */}
          <div className="cardFlexCenterForSt">
            {/* first card */}
            <div className="cardWidthforStudnet">
              <div className="d-flex">
                <div className="arrowCardForStudent">
                  <p>
                    Search the tutor as per your needs like class, subject,
                    board, language etc.
                  </p>
                </div>
                <div className="arrrowFixCenterForStudent">
                  <EastIcon />
                </div>
              </div>
            </div>
            {/* second card */}
            <div className="cardWidthforStudnet">
              <div className="d-flex">
                <div className="arrowCardForStudent">
                  <p>
                    List of teachers matching the criteria along with the
                    details such as experience, profile, available time etc are
                    displayed.
                  </p>
                </div>
                <div className="arrrowFixCenterForStudent">
                  <EastIcon />
                </div>
              </div>
            </div>
            {/* third card */}
            <div className="cardWidthforStudnet">
              <div className="d-flex">
                <div className="arrowCardForStudent">
                  <p>
                    Select a teacher of your choice, timeslot and sign up for a
                    student account with details such as phone number of parent,
                    email address, address, class, board etc.
                  </p>
                </div>
                <div className="arrrowFixCenterForStudent">
                  <EastIcon />
                </div>
              </div>
            </div>
            {/* fourth card */}
            <div style={{ width: "20%" }}>
              <div className="d-flex">
                <div className="arrowCardForStudent">
                  <p> The first class will be free of charge.</p>
                  <p>
                    After the first class, students would need to make payment
                    for the next 8 classes before the next class.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* mobile view */}
          <div className="cardFlexCenterMobile">
            {/* first card */}
            <div>
              <div className="d-flex">
                <div className="arrowCardForStudent">
                  <p>
                    Search the tutor as per your needs like class, subject,
                    board, language etc.
                  </p>
                </div>
              </div>
              <div className="bottomArrow">
                <SouthIcon />
              </div>
            </div>
            {/* second card */}
            <div>
              <div className="d-flex">
                <div className="arrowCardForStudent">
                  <p>
                    List of teachers matching the criteria along with the
                    details such as experience, profile, available time etc are
                    displayed.
                  </p>
                </div>
              </div>
              <div className="bottomArrow">
                <SouthIcon />
              </div>
            </div>
            {/* third card */}
            <div>
              <div className="d-flex">
                <div className="arrowCardForStudent">
                  <p>
                    Select a teacher of your choice, timeslot and sign up for a
                    student account with details such as phone number of parent,
                    email address, address, class, board etc.
                  </p>
                </div>
              </div>
              <div className="bottomArrow">
                <SouthIcon />
              </div>
            </div>
            {/* fourth card */}
            <div>
              <div className="d-flex">
                <div className="arrowCardForStudent">
                  <p> The first class will be free of charge.</p>
                  <p>
                    After the first class, students would need to make payment
                    for the next 8 classes before the next class.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="findBuutonCenterHowItWokr">
          <button
            className="common_button"
            onClick={() => {
              findTutorsHandle();
            }}
          >
            Find Tutor
          </button>
        </div>
      </div>
      {/* ---------finf tutors dailog----- */}
      {tutorsDailogOpen && <FindTutorDailog open={findTutorsHandle} />}
    </>
  );
};

export default HOC(PtStudentHowItWork);
