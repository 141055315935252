import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { FaqsArray } from "../LocalData/LocalData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Faqs = () => {
  return (
    <div>
      <div className="faq_main_div common_div_padding">
        <div>
          <h2 className="Faq_heading">FAQs</h2>
        </div>
        <div className="Faq_question">
          {FaqsArray.map((row, index) => (
            <div className="faq_accordion_margin">
              <Accordion className="learning_and_assignment">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  {" "}
                  <Typography className="faqheading">
                    {row.Heading}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="faq_para_color">{row.para}</Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
