import React from "react";
import Card from "@mui/material/Card";

const FeatureCard = (props) => {
  const { Image, Heading, Para } = props;
  return (
    <div className="cardApddingMargin">
      <div className="flexCardContent">
        <img src={Image} alt="no_image" className="feature_count_image" />
        <div className="contentPaddingLeft">
          <p className="feature_cloud_plateform">{Heading}</p>
          <p className="feature_para_font">{Para}</p>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
