import React, { useState, useEffect } from "react";
import HOC from "../HOC/HOC";
import Grid from "@mui/material/Grid";
import { Card } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import "./StudentSelectTeacher.css";
import { addStudentForPrivateInstitute } from "../../services";
import { useMutation } from "@apollo/react-hooks";
import Loader from "../Loader/Loader";
import Swal from "sweetalert2";
import { getWeekDay } from "../../Utils/validation";

const config = require("../../config/default.json");

const StudentSelectTeacher = (props) => {
  console.log("props===========", props);
  const {
    // dayTime,
    countrySymbal,
    phoneNumber,
    curencyCodeValue,
    phoneWithOutDialCode,
    row,
  } = props.location?.state?.data;
  const [addStudent_ForPrivate_Institute, {}] = useMutation(
    addStudentForPrivateInstitute
  );

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [pFirstName, setPFirstName] = useState("");
  const [pFirstNameError, setPFirstNameError] = useState(false);
  const [pMiddleName, setPMiddleName] = useState("");
  const [pLastName, setPLastName] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [phoneValueError, setPhoneValueError] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [recaptchErr, setRecaptchaErr] = useState(false);
  const [preference, setPreference] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPhoneValue(phoneNumber);
  }, []);

  const formSubmitHandle = async () => {
    if (!firstName) {
      setFirstNameError(true);
    }
    if (!pFirstName) {
      setPFirstNameError(true);
    }
    if (!phoneValue) {
      setPhoneValueError(true);
    }
    const emailReg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailId.match(emailReg)) {
      setEmailIdError(true);
    }
    if (!startDate) {
      setStartDateError(true);
    }
    if (
      !firstName ||
      !pFirstName ||
      !phoneValue ||
      !emailId.match(emailReg) ||
      !startDate
    ) {
      return;
    }
    setIsLoading(true);

    // dayTime.map((row) => {
    //   tempArr.push({
    //     dayTime: getWeekDay(row.day, row.time),
    //   });
    // });

    const { data } = await addStudent_ForPrivate_Institute({
      variables: {
        email: emailId,
        phoneNumber: phoneValue,
        student_first_name: firstName,
        student_middle_name: middleName,
        student_last_name: lastName,
        father_first_name: pFirstName,
        father_middle_name: pMiddleName,
        father_last_name: pLastName,
        institution_id: +row.id,
        preference: preference,
        start_date: startDate,
        Preferred_day_time: [],
        teacherEmailID: row.employees[0]?.email,
        teacherPhoneNumer: row.employees[0]?.phone,
        teacher_id: row.employees[0]?.id,
        language: row.languages[0].name,
        subject: row.subjects[0].name,
        board: "",
        start_time: "",
        day: "",
      },
    });
    if (data) {
      Swal.fire({
        title:
          "We have received your request. You will be notifed through email once teacher is assigned to you.",
      })
        .then((result) => {
          console.log("result", result);
          if (result.isConfirmed) {
            window.location = "/";
          }
          // when confirmed and promise resolved...
        })
        .catch((error) => {
          // when promise rejected...
        });
    }
    setIsLoading(false);
  };
  //input date min date
  let firstDay = new Date();
  let nextWeek = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000);
  // var d = new Date();
  let day = nextWeek.getDate();
  let year = nextWeek.getFullYear();
  let month = nextWeek.getMonth() + 1;
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  let dfulldateyesterday = year + "-" + month + "-" + day;

  console.log("dfulldateyesterday", dfulldateyesterday);
  return (
    <div className="website_home_main_div">
      <div className="SelectTuroPadding">
        <div>
          <Card>
            <div className="p-3">
              <div>
                <h5>Student Name</h5>
                <div className="ptFomrBorder">
                  <Grid className="common_main_grid">
                    <Grid item md={4}>
                      <div className="EmailPadding">
                        <div className="ptFormlabel">
                          First Name <span className="text-danger">*</span>
                        </div>
                        <input
                          type="text"
                          placeholder="First name"
                          className="form-control"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                            setFirstNameError(false);
                          }}
                        />
                        {firstNameError && (
                          <span className="blank_error">Enter First Name</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div className="EmailPadding">
                        <div className="ptFormlabel">Middle Name</div>
                        <input
                          type="text"
                          placeholder="Last name"
                          className="form-control"
                          value={middleName}
                          onChange={(e) => {
                            setMiddleName(e.target.value);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div className="EmailPadding">
                        <div className="ptFormlabel">Last Name</div>
                        <input
                          type="text"
                          placeholder="Last name"
                          className="form-control"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="pt-2">
                <h5>Parent Name</h5>
                <div className="ptFomrBorder">
                  <Grid className="common_main_grid">
                    <Grid item md={4}>
                      <div className="EmailPadding">
                        <div className="ptFormlabel">
                          First Name <span className="text-danger">*</span>
                        </div>
                        <input
                          type="text"
                          placeholder="First name"
                          className="form-control"
                          value={pFirstName}
                          onChange={(e) => {
                            setPFirstName(e.target.value);
                            setPFirstNameError(false);
                          }}
                        />
                        {pFirstNameError && (
                          <span className="blank_error">Enter First Name</span>
                        )}
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div className="EmailPadding">
                        <div className="ptFormlabel">Middle Name</div>
                        <input
                          type="text"
                          placeholder="Last name"
                          className="form-control"
                          value={pMiddleName}
                          onChange={(e) => {
                            setPMiddleName(e.target.value);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={4}>
                      <div className="EmailPadding">
                        <div className="ptFormlabel">Last Name</div>
                        <input
                          type="text"
                          placeholder="Last name"
                          className="form-control"
                          value={pLastName}
                          onChange={(e) => {
                            setPLastName(e.target.value);
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <Grid className="common_main_grid">
                <Grid item md={4}>
                  <div className="EmailPadding">
                    <div className="ptFormlabel">
                      Phone Number <span className="text-danger">*</span>
                    </div>
                    <PhoneInput
                      className="phoneInputheight"
                      country={"in"}
                      countryCodeEditable={false}
                      value={phoneValue}
                      disableDropdown={true}
                      disabled={true}
                      onChange={(phone, formattedValue) => {
                        setPhoneValue(phone);
                        setPhoneValueError(false);
                      }}
                    />
                    {phoneValueError && (
                      <span className="blank_error">Enter Phone Number</span>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid className="common_main_grid">
                <Grid item md={4}>
                  <div className="EmailPadding">
                    <div className="ptFormlabel">
                      Email Address <span className="text-danger">*</span>
                    </div>
                    <input
                      type="text"
                      placeholder="Email"
                      className="form-control"
                      value={emailId}
                      onChange={(e) => {
                        setEmailId(e.target.value);
                        setEmailIdError(false);
                      }}
                    />
                    {emailIdError && (
                      <span className="blank_error">Enter Email Id</span>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid className="common_main_grid">
                <Grid item md={4}>
                  <div className="EmailPadding">
                    <div className="ptFormlabel">Preference</div>
                    <div className="d-flex">
                      <div className="form-check mt-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value={"Group"}
                          onChange={(e) => {
                            setPreference(e.target.value);
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Group class
                        </label>
                      </div>
                      <div className="form-check mt-2 ml-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value={"one-to-one"}
                          onChange={(e) => {
                            setPreference(e.target.value);
                          }}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          One-to-One
                        </label>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid className="common_main_grid">
                <Grid item md={4}>
                  <div className="EmailPadding">
                    <div className="ptFormlabel">
                      Start Date (Date will be one week after current date)<span className="text-danger">*</span>
                    </div>
                    <input
                      type="date"
                      min={dfulldateyesterday}
                      placeholder="date"
                      className="form-control"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                        setStartDateError(false);
                      }}
                    />
                    {startDateError && (
                      <span className="blank_error">Enter start date</span>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid className="common_main_grid">
                {/* <Grid item md={4}>
                  <div className="EmailPadding">
                    <div className="ptFormlabel">Preferred Day/Time</div>
                    {dayTime &&
                      dayTime.map((row, index) => (
                        <p key={index} className="PreferredTimeColor">
                          {getWeekDay(row.day, row.time)}
                        </p>
                      ))}
                  </div>
                </Grid> */}
              </Grid>
              <div className="EmailPadding">
                {" "}
                <ReCAPTCHA
                  sitekey={config.app.siteKey}
                  // onChange={callback}
                  onChange={(code) => {
                    if (recaptchErr) setRecaptchaErr(false);
                    setRecaptchaValue(code);
                  }}
                />
              </div>
              <div className="EmailPadding">
                <button
                  className="common_button"
                  onClick={() => {
                    formSubmitHandle();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <Loader loading={isLoading} />
    </div>
  );
};

export default HOC(StudentSelectTeacher);
