import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import HOC from "../HOC/HOC";
import "./Blogs.css";
import { useNavigate } from "react-router-dom";
import { BlogsArrayData } from "./BlogsData";

const Blogs = (props) => {
  const navigate = useNavigate();
  const [mostReadArray, setMostReadArray] = useState([]);
  const [cardArray, setCardArray] = useState([]);
  let blogData = [];
  useEffect(() => {
    blogData = BlogsArrayData;
    getBlogData();
  }, []);

  const getBlogData = async () => {
    await setMostReadArray(blogData.slice(1, 4));
    await setCardArray(blogData.slice(4));
  };

  return (
    <div className="blogsmainDiv">
      <div className="blogPadding">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <div>
              <p className="cardHeadingColor">Featured Post</p>
              <div
                className="cursor"
                onClick={() => {
                  navigate(BlogsArrayData[0].location);
                }}
              >
                <img
                  src={BlogsArrayData[0].image}
                  width="100%"
                  className="imageBorderBlogs"
                  alt="No_image"
                />
                <div className="blogContentSet">
                  <p>{BlogsArrayData[0].topic}</p>
                  <h4>{BlogsArrayData[0].heading}</h4>
                  <span>{BlogsArrayData[0].date}</span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div style={{ paddingLeft: "8%" }}>
              <p className="cardHeadingColor">Most Read</p>
              {mostReadArray.map((row, index) => (
                <div
                  className="blogTitleFlex cursor"
                  key={index}
                  onClick={() => {
                    navigate(row.location);
                  }}
                >
                  <div>
                    <img
                      src={row.image}
                      width={100}
                      height={90}
                      className="blogRightGridImage"
                      alt="No_image"
                    />
                  </div>
                  <div className="blogstitleSpace">
                    <div className="blogsspaceBtewwen">
                      <p className="topic">{row.topic}</p>
                      <p className="topic">{row.date}</p>
                    </div>
                    <p className="heading">{row.heading}</p>
                  </div>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
      {/* --------card section---------- */}
      <div>
        <div className="blogCardflex">
          {cardArray.map((row, index) => (
            <div className="BlogCardBorder" key={index}>
              <img
                src={row.image}
                width={"100%"}
                className="blogArrayImageBorder"
                alt="No_image"
                onClick={() => {
                  navigate(row.location);
                }}
              />
              <p>{row.topic}</p>
              <h2>{row.heading}</h2>
              <div>
                <span>{row.date}</span>
              </div>
              <div className="borderShadow"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HOC(Blogs);
