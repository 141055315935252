import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import "./WebsiteHomepage.css";
import "../../Common.css";
import TextField from "@mui/material/TextField";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import MobileHero from "../Images/MobileHero.png";
import axios from "../../services/axios";
import ReCAPTCHA from "react-google-recaptcha";
import SignUpDailog from "../SignUp/SignUpDailog";
import ConfirmNumber from "../SignUp/ConfirmNumber";
import FinishSignUp from "../SignUp/FinishSignUp";
import MessagePopUp from "../SignUp/MessagePopUp";
import JoinMeetingDailog from "./JoinMeetingDailog";
import checkbox from "../Images/checkbox.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FindTutorDailog from "./FindTutorDailog";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "@material-ui/core";
const config = require("../../config/default.json");

const ScheduleMeeting = () => {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState(false);
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [emailIdError, setEmailIdError] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startDateError, setStartDateError] = useState(false);
  const [recaptchErr, setRecaptchaErr] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [dailogOpen, setDailogOpen] = useState(false);
  const [confirmDailogOpen, setConfirmDailogOpen] = useState(false);
  const [openFinishDailog, setopenFinishDailog] = useState(false);
  const [responseId, setResponseId] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [dialogOpen, setdialogOpen] = useState(false);
  const [joinDailogOpen, setJoinDailogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [curencyCodeValue, setCurencyCodeValue] = useState("");
  const [countrySymbal, setCountrySymbal] = useState("");
  const [tutorsDailogOpen, setTutorsDailogOpen] = useState(false);

  const SheduleMeeting = async () => {
    try {
      if (fullName === "") {
        setFullNameError(true);
        return;
      }
      if (phoneNumber === "") {
        setPhoneNumberError(true);
        return;
      }
      const emailReg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailId.match(emailReg)) {
        setEmailIdError(true);
        return;
      }
      if (startDate === "") {
        setStartDateError(true);
        return;
      }
      console.log("satra", startDate);
      if (!recaptchaValue) {
        setRecaptchaErr(true);
        return;
      }
      const { data } = await axios.post("/enquirySchool", {
        email: emailId,
        phone: phoneNumber,
        date: startDate,
        name: fullName,
      });
      if (data) console.log("data", data);
    } catch (error) {
      console.log("error", error);
    }
  };
  var callback = function () {
    console.log("Done!!!!");
  };

  // sign up dailog open first
  const signUpHandle = () => {
    setDailogOpen(!dailogOpen);
  };

  // confirm dailog open
  const continueHandle = () => {
    setConfirmDailogOpen(!confirmDailogOpen);
    setDailogOpen(false);
  };
  const otpId = (id, phoneNo, curencyCode, countryValue) => {
    setResponseId(id);
    setphoneNumber(phoneNo);
    setCurencyCodeValue(curencyCode);
    setCountrySymbal(countryValue);
  };

  // confirm button handle
  const VerifiyHandle = () => {
    setopenFinishDailog(!openFinishDailog);
    setConfirmDailogOpen(false);
    setDailogOpen(false);
  };
  // finish handle
  const finishHandle = () => {
    setopenFinishDailog(false);
    setdialogOpen(true);
  };
  // ok button handle
  const messagePopUpClose = () => {
    setdialogOpen(false);
    window.open("https://liveweb.skoolfirst.com/");
  };
  // join button clcik
  const joinButtonHandle = () => {
    setJoinDailogOpen(!joinDailogOpen);
  };

  // error otp mesage------
  const ErrorMessageHandle = () => {
    setErrorMessage(!errorMessage);
    setConfirmDailogOpen(false);
  };
  // ok button handle
  const errorMessageOkHandle = () => {
    setErrorMessage(false);
  };
  const findTutorsHandle = () => {
    setTutorsDailogOpen(!tutorsDailogOpen);
  };

  return (
    <div>
      <div className="sechedule_meeting_main_div">
        <Grid className="common_main_grid" style={{ marginTop: "4%" }}>
          <Grid item md={4}>
            <div className="shcedlue_meeting_padding_both">
              <Card className="cardBorderColor">
                <div className="schedule_meeting_digital">
                  <div>
                    <h1>
                      Looking for a <span>tutor?</span>{" "}
                    </h1>
                  </div>
                  <ul>
                    <li className="d-flex">
                      <img
                        src={checkbox}
                        className="checkImageWidth"
                        alt="no_image"
                      />
                      <div>
                        Search teachers for your subject, language, board and
                        class.
                      </div>
                    </li>
                    <li className="d-flex">
                      <img
                        src={checkbox}
                        className="checkImageWidth"
                        alt="no_image"
                      />
                      <div>Choose your teacher.</div>
                    </li>
                    <li className="d-flex">
                      <img
                        src={checkbox}
                        className="checkImageWidth"
                        alt="no_image"
                      />
                      <div>Create account to get started.</div>
                    </li>
                  </ul>
                  <div className="flexSpaceBottom">
                    <button
                      className="common_button"
                      onClick={() => {
                        findTutorsHandle();
                      }}
                    >
                      Find Tutor
                    </button>
                    <p className="howItWorkedFont">
                      <a href="/PtStudentHowItWork">How it works?</a>
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shcedlue_meeting_padding_both">
              <Card className="cardBorderColor">
                <div className="schedule_meeting_digital">
                  <div>
                    <h1>
                      Register as a <span>tutor!!</span>{" "}
                    </h1>
                  </div>

                  <ul>
                    <li className="d-flex">
                      <img
                        src={checkbox}
                        className="checkImageWidth"
                        alt="no_image"
                      />
                      <div>Set your tuition fees and availability.</div>
                    </li>
                    <li className="d-flex">
                      <img
                        src={checkbox}
                        className="checkImageWidth"
                        alt="no_image"
                      />
                      <div>Get notified when a student selects you.</div>
                    </li>
                    <li className="d-flex">
                      <img
                        src={checkbox}
                        className="checkImageWidth"
                        alt="no_image"
                      />
                      <div>Get Paid for classes conducted.</div>
                    </li>
                  </ul>
                  <div className="flexSpaceBottom">
                    <button
                      className="common_button"
                      onClick={() => {
                        // signUpHandle();
                        navigate("/CreateTeacherAccount");
                      }}
                    >
                      Signup as a Teacher
                    </button>
                    <p className="howItWorkedFont">
                      <a href="/PtTeacherHowItWork">How it works?</a>
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          </Grid>
          <Grid item md={4}>
            <div className="shcedlue_meeting_padding_both">
              <Card className="cardBorderColor">
                <div className="schedule_meeting_digital">
                  <div className="flexSpace">
                    <h1>
                      Join as a <span>school/institution !!</span>{" "}
                    </h1>
                  </div>
                  <ul>
                    <li className="d-flex">
                      <img
                        src={checkbox}
                        className="checkImageWidth"
                        alt="no_image"
                      />
                      <div> Manage your school online.</div>
                    </li>
                    <li className="d-flex">
                      <img
                        src={checkbox}
                        className="checkImageWidth"
                        alt="no_image"
                      />
                      <div>Manage multibranches using single login.</div>
                    </li>
                    <li className="d-flex">
                      <img
                        src={checkbox}
                        className="checkImageWidth"
                        alt="no_image"
                      />
                      <div>Parent and Student portal.</div>
                    </li>
                    <li className="d-flex">
                      <img
                        src={checkbox}
                        className="checkImageWidth"
                        alt="no_image"
                      />
                      <div>Admin portal to manage all admin activities.</div>
                    </li>
                    <li className="d-flex">
                      <img
                        src={checkbox}
                        className="checkImageWidth"
                        alt="no_image"
                      />
                      <div>
                        {" "}
                        And <a href="/#features">many more… </a>.
                      </div>
                    </li>
                  </ul>
                  <div className="flexSpaceBottom">
                    <button
                      className="common_button mt-2"
                      onClick={() => {
                        joinButtonHandle();
                      }}
                    >
                      Contact us for more details
                    </button>
                  </div>
                </div>
              </Card>
            </div>
          </Grid>
        </Grid>
        {/* <Grid className="common_main_grid">
          <Grid item md={6}>
            <div className="shcedlue_meeting_padding_both">
              <div className="schedule_meeting_digital">
                <h1>
                  Manage your online <span> teaching </span>
                </h1>
                <p className="paraPaddingtop">
                  All your teaching needs at one place from 1:1 classes to any
                  number of students.
                </p>
                <ul>
                  <li>
                    <img src={checkbox} className="checkImageWidth" />
                    Online Class
                  </li>
                  <li>
                    {" "}
                    <img src={checkbox} className="checkImageWidth" />
                    Study Materials
                  </li>
                  <li>
                    {" "}
                    <img src={checkbox} className="checkImageWidth" />
                    Class Mangement
                  </li>
                  <li>
                    {" "}
                    <img src={checkbox} className="checkImageWidth" />
                    Student Assignments
                  </li>
                  <li>
                    {" "}
                    <img src={checkbox} className="checkImageWidth" />
                    Student fee invoicing and{" "}
                    <a href="/#features"> many more...</a>
                  </li>
                </ul>

                <div>
                  <button
                    className="common_button signupButtonFont"
                    onClick={() => {
                      signUpHandle();
                    }}
                  >
                    Sign up now for 1 month free trial
                  </button>
                </div>
                <p className="paraPaddingtop" style={{ textAlign: "center" }}>
                  Or{" "}
                  <span
                    className="cursor joinFontSize"
                    onClick={() => {
                      joinButtonHandle();
                    }}
                  >
                    Join{" "}
                  </span>{" "}
                  us as a school/tuition center
                </p>
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div>
              {" "}
              <img
                src={MobileHero}
                alt="no_image"
                className="schedule_mobile_logo"
              />
            </div>
          </Grid>
        </Grid> */}
      </div>

      {/* -------first dailog---------- */}
      {dailogOpen && (
        <SignUpDailog
          open={signUpHandle}
          continueHandle={continueHandle}
          otpId={otpId}
          VerifiyHandle={VerifiyHandle}
        />
      )}
      {/* -------second dailog--------- */}
      {confirmDailogOpen && (
        <ConfirmNumber
          open={continueHandle}
          VerifiyHandle={VerifiyHandle}
          responseId={responseId}
          phoneNumber={phoneNumber}
          ErrorMessageHandle={ErrorMessageHandle}
        />
      )}
      {/* -------third dailog---------- */}
      {openFinishDailog && (
        <FinishSignUp
          open={VerifiyHandle}
          finishHandle={finishHandle}
          phoneNumber={phoneNumber}
          curencyCodeValue={curencyCodeValue}
          countrySymbal={countrySymbal}
        />
      )}
      {/* ---------pop message----------- */}
      {dialogOpen && (
        <MessagePopUp
          dialogOpen={dialogOpen}
          messagePopUpClose={messagePopUpClose}
        >
          <div className="contentCenter">
            <div>
              <CheckCircleIcon className="iconFontSize" />
            </div>
            <div>
              <p>Congratulations !!</p>
              <p>Please check your Email for Login ID and Password.</p>
            </div>
            <div>
              <button
                className="buttonColorWidth"
                onClick={() => {
                  messagePopUpClose();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </MessagePopUp>
      )}
      {/* ---------otp message error */}
      {errorMessage && (
        <MessagePopUp
          dialogOpen={ErrorMessageHandle}
          messagePopUpClose={errorMessageOkHandle}
        >
          <div className="contentCenter">
            <div>
              <HighlightOffIcon className="iconFontSizeClose" />
            </div>
            <div>
              <p>
                You have exceeded max number of tries. Please contact us at
                <a href="contact@epalsoftware.com" target="_blank">
                  {" "}
                  contact@epalsoftware.com
                </a>
                .
              </p>
            </div>
            <div>
              <button
                className="buttonColorWidthError"
                onClick={() => {
                  errorMessageOkHandle();
                }}
              >
                OK
              </button>
            </div>
          </div>
        </MessagePopUp>
      )}

      {/*---------- join Dailog---------- */}
      {joinDailogOpen && <JoinMeetingDailog open={joinButtonHandle} />}
      {/* ---------finf tutors dailog----- */}
      {tutorsDailogOpen && <FindTutorDailog open={findTutorsHandle} />}

      {/* <div className="sechedule_meeting_main_div">
        <Grid className="common_main_grid">
          <Grid item md={6}>
            <div className="shcedlue_meeting_padding_both">
              <div className="schedule_meeting_digital">
                <h1>
                  Digitally transforming the world of <span> education </span>
                </h1>
                <p>
                  {" "}
                  Tailor-made, customisable solutions for your educational
                  institution that helps you to reduce the load of documentation
                  on your faculty and administrative staff, forms a network for
                  students, teachers and parents and let’s you deliver quality
                  education in a technologically advanced way.
                </p>
              </div>
              <div>
                <img
                  src={MobileHero}
                  alt="no_image"
                  className="schedule_mobile_logo"
                />
              </div>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className="schedule_meeting_form">
              <div className="shcedlue_meeting_padding_both">
                <div className="schdeule_right_grid_width">
                  <div className="schedule_padding_filed">
                    <TextField
                      id="standard-basic"
                      label="Full name"
                      variant="standard"
                      className="common_textfield_width"
                      value={fullName}
                      onChange={(e) => {
                        setFullName(e.target.value);
                        setFullNameError(false);
                      }}
                    />
                    {fullNameError && (
                      <span className="blank_error">Enter full name</span>
                    )}
                  </div>
                  <div className="schedule_padding_filed">
                    <TextField
                      id="standard-basic"
                      label="Phone number"
                      variant="standard"
                      className="common_textfield_width"
                      value={phoneNumber}
                      onChange={(e) => {
                        if (isNaN(e.target.value)) {
                          return;
                        }
                        // setPhoneNumber(e.target.value);
                        setPhoneNumberError(false);
                      }}
                    />
                    {phoneNumberError && (
                      <span className="blank_error">Enter phone number</span>
                    )}
                  </div>
                  <div className="schedule_padding_filed">
                    <TextField
                      id="standard-basic"
                      label="Email Address"
                      variant="standard"
                      className="common_textfield_width"
                      value={emailId}
                      onChange={(e) => {
                        setEmailId(e.target.value);
                        setEmailIdError(false);
                      }}
                    />
                    {emailIdError && (
                      <span className="blank_error">Enter Email Id</span>
                    )}
                  </div>
                  <div className="schedule_padding_filed">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        label="Date"
                        views={["year", "month", "date"]}
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e);
                        }}
                        className="common_textfield_width"
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="mt-3 my_recaptcha">
                    <p>
                      <ReCAPTCHA
                        sitekey={config.app.siteKey}
                        onChange={callback}
                        onChange={(code) => {
                          if (recaptchErr) setRecaptchaErr(false);
                          setRecaptchaValue(code);
                        }}
                      />
                    </p>
                    {recaptchErr && (
                      <p className="text-danger">Please validate recaptcha</p>
                    )}
                  </div>
                  <div className="schedule_button_fix">
                    <button
                      className="common_button"
                      onClick={() => {
                        SheduleMeeting();
                      }}
                    >
                      Schedule a Meeting
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div> */}
    </div>
  );
};

export default ScheduleMeeting;
